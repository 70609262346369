import {
  ExtendedInvoice,
  InvoicePayment,
  OnSuccessHandlers,
} from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Col, Divider, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Accent, CmsTooltip, CustomButton } from '../core';
import {
  DocumentViewErrorComponent,
  DocumentViewModal,
  PdfView,
} from '../document-view';
import { CurrencyDisplay } from '../formatted-display';
import {
  ArchiveButton,
  AutoHandleButton,
  AutoPayButton,
  DeleteButton,
  HandleButton,
  NoteButton,
  PaymentButton,
} from '../invoice-buttons';
import { InvoicePayments } from './invoice-payments/invoicePayments';
import { InvoiceNoteList } from './invoiceNoteList';

type InvoiceViewModeProps = {
  invoice?: ExtendedInvoice;
  invoicePayment?: InvoicePayment;
  showPdf?: boolean;
  onSuccess?: OnSuccessHandlers;
  isAdmin: boolean;
  enableEditMode: () => void;
};

export const InvoiceViewMode: FC<InvoiceViewModeProps> = ({
  invoice,
  showPdf,
  onSuccess,
  invoicePayment,
  isAdmin,
  enableEditMode,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={40} style={{ height: '100%' }}>
      <Col sm={24} md={showPdf ? 13 : 24}>
        <Row justify="space-between" align="bottom">
          <Col>
            <Accent style={{ marginBottom: 10 }} color="secondary">
              {t('label.invoice')}
            </Accent>
            <Accent type="h2">{invoice?.invoiceNumber}</Accent>
          </Col>
          {invoice?.invoiceDate && (
            <Col>
              <Accent style={{ marginBottom: 10 }} color="secondary">
                {t('label.invoiceDate')}
              </Accent>
              <Accent type="h2">
                {dateTimeFormatter.toDateOnly(invoice?.invoiceDate)}
              </Accent>
            </Col>
          )}
          <Col>
            <Row justify="end">
              <CmsTooltip toolTipKey="invoiceDetail.label.invoicetype">
                <Badge
                  style={{ marginBottom: 10 }}
                  count={t(`invoiceTypes.${invoice?.type}`)}
                />
              </CmsTooltip>
            </Row>
            <Row justify="end">
              <Accent type="h2" color="tertiary">
                <CurrencyDisplay amount={invoice?.amount ?? 0} />
              </Accent>
            </Row>
          </Col>
          <Col span={24}>{invoice?.counterInvoiceRelation.name}</Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          {invoice && (
            <Col className="button__group">
              <PaymentButton
                iconOnly={true}
                IsInPayment={invoice?.isInPayment}
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onPaymentSuccess}
                isDisabled={!invoice.isPayable}
              />
              {!invoice.isArchived && (
                <HandleButton
                  isHandled={!invoice.isHandled}
                  invoiceId={invoice.invoiceId}
                  onSuccess={onSuccess?.onHandleSuccess}
                />
              )}

              <Divider type="vertical" />
              <DocumentViewModal
                id={invoice.sourceIdentifier}
                idType="processfile"
                data={invoice}
              />
              <NoteButton
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onCommentSuccess}
              />
              <AutoHandleButton
                isAutoHandled={invoice?.isAutoHandled ?? false}
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onAutoHandleSuccess}
              />
              <AutoPayButton
                isAutoPaid={invoice?.isAutoPaid ?? false}
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onAutoPaidSuccess}
              />
              <ArchiveButton
                isArchived={invoice.isArchived}
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onArchiveSuccess}
              />

              {isAdmin && (
                <CustomButton
                  shape="circle"
                  color="primary"
                  type="link"
                  disabled={!isAdmin}
                  onClick={enableEditMode}
                  icon={<FontAwesomeIcon icon="pencil-alt" />}
                  toolTipKey="invoiceDetail.action.edit"
                />
              )}
              <DeleteButton
                invoiceId={invoice.invoiceId}
                onSuccess={onSuccess?.onDeleteSuccess}
              />
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col>
            <CmsTooltip toolTipKey="invoiceDetail.label.payment">
              <Accent type="h2" color="secondary">
                {t('label.payment', {
                  count: invoicePayment?.paymentEntries?.length,
                })}
              </Accent>
            </CmsTooltip>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <InvoicePayments invoicePayment={invoicePayment} />
          </Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col>
            <CmsTooltip toolTipKey="invoiceDetail.label.comment">
              <Accent type="h2" color="secondary">
                {t('label.invoiceNote', {
                  count: invoice?.notes.length,
                })}
              </Accent>
            </CmsTooltip>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <InvoiceNoteList invoiceNotes={invoice?.notes ?? []} />
          </Col>
        </Row>
      </Col>
      {showPdf && (
        <Col span={24} md={11} style={{ height: '100%' }}>
          <PdfView
            url={invoice?.fileUrl}
            onErrorComponent={
              <DocumentViewErrorComponent idType="invoice" data={invoice} />
            }
          />
        </Col>
      )}
    </Row>
  );
};
