import { Modal, Row, Spin } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../buttons';

type ModalHandlerResponse = Promise<boolean | void> | boolean | void;
export interface GenericModalChildProps {
  onOkHandler?: (e) => ModalHandlerResponse;
  onCancelHandler?: (e) => ModalHandlerResponse;
  isLoading: boolean;
}

export interface GenericModalProps extends PropsWithChildren {
  title?: ReactNode;
  onOkHandler?: (e) => ModalHandlerResponse;
  onCancelHandler?: (e) => ModalHandlerResponse;
  okText?: ReactNode;
  isVisible: boolean;
  hideModal: () => void;
  isLoading?: boolean;
  bodyStyle?: React.CSSProperties;
  destroyOnClose?: boolean;
  footer?: boolean;
  width?: string | number;
}

export const GenericModal: React.FunctionComponent<GenericModalProps> = ({
  isVisible,
  hideModal,
  children,
  isLoading = false,
  okText,
  onOkHandler,
  onCancelHandler,
  bodyStyle,
  footer,
  ...restProps
}) => {
  const onOk = async (e) => {
    const keepOpen = await onOkHandler?.(e);
    if (!keepOpen) {
      hideModal();
    }
    return keepOpen;
  };

  const onCancel = async (e) => {
    const keepOpen = await onCancelHandler?.(e);
    if (!keepOpen) {
      hideModal();
    }
    return keepOpen;
  };

  return (
    <Modal
      footer={
        footer === false ? (
          false
        ) : (
          <ModalFooter
            isLoading={isLoading}
            onCancelHandler={onCancel}
            onOkHandler={onOk}
            okText={okText}
          />
        )
      }
      open={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      {...restProps}
    >
      <Spin spinning={isLoading}>
        <div style={{ ...bodyStyle }}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(<>{child}</>, {
                onOkHandler,
                onCancelHandler,
              });
            }
            return child;
          })}
        </div>
      </Spin>
    </Modal>
  );
};

interface ModalFooterProps extends GenericModalChildProps {
  okText: ReactNode;
}

const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({
  onCancelHandler,
  onOkHandler,
  isLoading,
  okText,
}) => {
  const { t } = useTranslation();
  return (
    <Row justify="space-between">
      <CustomButton
        onClick={onCancelHandler}
        ghost
        color="secondary"
        loading={isLoading}
      >
        {t('general.actions.cancel')}
      </CustomButton>
      <CustomButton onClick={onOkHandler} color="secondary" loading={isLoading}>
        {okText || t('general.actions.save')}
      </CustomButton>
    </Row>
  );
};
