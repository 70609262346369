import { getIsInPaymentInvoices } from '@/lib/adapters/invoice-adapter';
import {
  hasAccessAtom,
  keycloakInstanceAtom,
  payableInvoiceCountAtom,
} from '@/lib/atoms/atoms';
import { CustomButton, Logo, Tracking } from '@/lib/components';
import { useCurrentUserContext, useRelationContext } from '@/lib/context';
import { useGetNotifications } from '@/lib/queries';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Col, Grid, Modal, Popover, Row, Space } from 'antd';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderMenu } from './headerMenu';
import { NotificationList } from './notificationList';
import styles from './styles.module.scss';
import { UploadButton } from './uploadButton';
import { UserAvatar } from './userAvatar';

interface IHeader {
  icon?: IconProp;
  title?: string;
}

const { useBreakpoint } = Grid;

export const Header: FC<IHeader> = () => {
  const { relation, setRelationId } = useRelationContext();
  const user = useCurrentUserContext();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [payableInvoiceCount, setPayableInvoiceCount] = useAtom(
    payableInvoiceCountAtom,
  );
  const [keycloak] = useAtom(keycloakInstanceAtom);
  const hasAccess = useAtomValue(hasAccessAtom);

  useEffect(() => {
    if (hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) {
      getIsInPaymentInvoices().then((r) =>
        setPayableInvoiceCount(r.data.length),
      );
    }
  }, []);

  const { data: notifications, refetch: refetchNotifications } =
    useGetNotifications(
      {
        category: 'General',
        pageSize: 1,
      },
      1000 * 30,
    );

  const { data: invoiceNotifications, refetch: refetchInvoiceNotifications } =
    useGetNotifications(
      {
        category: 'InvoiceStatus',
        pageSize: 1,
      },
      1000 * 30,
    );

  const signOut = () => {
    keycloak.logout();
    removeSelectedImpersonation();
  };

  const removeSelectedImpersonation = () => setRelationId(undefined);

  const showNotifications = () => setShowModal(true);
  const hideNotifications = () => setShowModal(false);

  return (
    <>
      <Row justify="start" align="middle" className={styles.header}>
        <Col flex={screens.xl ? '160px' : '80px'}>
          <Logo text="Powered by Alfa" iconOnly={!screens.xl && !screens.xxl} />
        </Col>
        <Col lg={4} xl={7} xxl={8}>
          <HeaderMenu mode="horizontal" iconOnly={false} />
        </Col>
        <Col flex="auto">
          <Row justify="center" align="middle">
            <Col>
              <Badge>
                <UploadButton />
              </Badge>
            </Col>
            <Col>
              <Badge count={invoiceNotifications?.unread}>
                <CustomButton
                  disabled={!relation}
                  onClick={showNotifications}
                  color="secondary"
                  toolTipKey="menu.label.invoiceTracking"
                >
                  {t('nav.tracking')}
                </CustomButton>
              </Badge>
            </Col>
          </Row>
        </Col>
        <Col>
          <Space direction="horizontal" size="small">
            <UserAvatar
              relation={relation}
              username={user?.username}
              extra={
                hasAccess.canImpersonate ||
                (user?.relations && user.relations.length > 1) ? (
                  !relation ? (
                    <CustomButton
                      shape="circle"
                      onClick={() => navigate('/relation-selection')}
                      style={{ color: 'white' }}
                      toolTipKey="menu.action.impersonate"
                      color="tertiary"
                      icon={<FontAwesomeIcon icon="users" />}
                    />
                  ) : (
                    <CustomButton
                      onClick={() => removeSelectedImpersonation()}
                      shape="circle"
                      toolTipKey="menu.action.undoImpersonate"
                      color="primary"
                      icon={<FontAwesomeIcon icon="users" />}
                    />
                  )
                ) : null
              }
            />
            <Popover
              destroyTooltipOnHide
              placement="bottomRight"
              trigger="click"
              content={
                <NotificationList
                  category="general"
                  style={{ paddingTop: 10, width: 525 }}
                  onChangeNotify={refetchNotifications}
                />
              }
            >
              <Badge count={notifications?.unread}>
                <CustomButton
                  disabled={!relation}
                  color="secondary"
                  shape="circle"
                  icon={<FontAwesomeIcon icon="bell" />}
                  toolTipKey="menu.label.notifications"
                />
              </Badge>
            </Popover>
            <Link to="/settings">
              <CustomButton
                disabled={!hasAccess.isAdmin && !relation}
                color="secondary"
                type="primary"
                shape="circle"
                toolTipKey="menu.label.settings"
                icon={<FontAwesomeIcon icon="cog" />}
              />
            </Link>
            <a target="_blank" href="https://www.aaff.nl/supportmijnalfa">
              <CustomButton
                color="secondary"
                type="primary"
                shape="circle"
                toolTipKey="menu.label.faq"
                icon={<FontAwesomeIcon icon="question-circle" />}
              ></CustomButton>
            </a>
            <CustomButton
              color="tertiary"
              type="primary"
              shape="circle"
              onClick={signOut}
              toolTipKey="menu.label.logout"
              icon={<FontAwesomeIcon icon="sign-out-alt" />}
            />
          </Space>
        </Col>
      </Row>
      <Modal
        destroyOnClose
        width={860}
        footer={false}
        bodyStyle={{ padding: 20 }}
        style={{
          padding: 0,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        onCancel={() => hideNotifications()}
        open={showModal}
      >
        <Tracking onChange={refetchInvoiceNotifications} />
      </Modal>
    </>
  );
};
