import { getInvoicePayments } from '@/lib/adapters/payment-adapter';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import { Heading } from '@/lib/components/core/typography/heading';
import { InvoicePayment } from '@/lib/types';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NettingInvoiceEntries } from './_components/NettingInvoiceEntries';
import { PaidInvoiceEntries } from './_components/PaidInvoiceEntries';

export const PaidInvoices = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [invoicePayment, setInvoicePayment] = useState<InvoicePayment[]>([]);

  useEffect(() => {
    setLoading(true);
    getInvoicePayments()
      .then(setInvoicePayment)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const tableColumns: CustomColumnType<InvoicePayment>[] = [
    {
      title: t('payment.header.created'),
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: t('payment.header.invoiceDate'),
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: t('payment.header.executionDate'),
      dataIndex: 'paymentEntries',
      key: 'paymentEntries',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
      enableDefaultSorter: false,
      render: (paymentEntries) => {
        if (!paymentEntries || paymentEntries.length === 0) {
          return null;
        }
        const latestEntry = paymentEntries.sort(
          (a, b) =>
            new Date(b.executionDate).getTime() -
            new Date(a.executionDate).getTime(),
        )[0];

        return latestEntry
          ? dayjs(latestEntry.executionDate).format('DD-MM-YYYY')
          : null;
      },
      onFilter: (value, record) => {
        const paymentEntries = record.paymentEntries || [];
        const latestEntry = paymentEntries.sort(
          (a, b) =>
            new Date(b.executionDate).getTime() -
            new Date(a.executionDate).getTime(),
        )[0];

        if (!latestEntry || !value) return false;
        return (
          dayjs(latestEntry.executionDate).format('YYYY-MM-DD') ==
          value.toString()
        );
      },
      sorter: (a, b) => {
        const getLatestExecutionDate = (entries) => {
          if (!entries || entries.length === 0) return 0;
          const latestEntry = entries.reduce((latest, current) =>
            new Date(current.executionDate) > new Date(latest.executionDate)
              ? current
              : latest,
          );
          return new Date(latestEntry.executionDate).getTime(); // Return timestamp
        };
        return getLatestExecutionDate(a.paymentEntries) <
          getLatestExecutionDate(b.paymentEntries)
          ? -1
          : 1;
      },
    },
    {
      title: t('payment.header.invoice'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: t('payment.header.supplier'),
      dataIndex: 'receiverName',
      key: 'supplier',
    },
    { title: t('payment.iban'), dataIndex: 'iban', key: 'iban' },
    {
      title: t('payment.header.invoiceAmount'),
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      defaultRender: 'currency',
    },
    {
      dataIndex: 'invoiceId',
      render: (invoiceId, record) => (
        <DocumentViewModal id={invoiceId} idType="invoice" />
      ),
      width: 20,
    },
  ];

  return (
    <Skeleton loading={loading}>
      <div>
        <Heading text={t('paid.title')} />
        <CustomTable
          columns={tableColumns}
          dataSource={invoicePayment}
          rowKey={(invoice) => invoice.invoiceId}
          expandable={{
            expandedRowRender: (invoice) => (
              <>
                {invoice.paymentEntries.length > 0 && (
                  <PaidInvoiceEntries paymentEntries={invoice.paymentEntries} />
                )}
                {invoice.nettingPaymentEntries.length > 0 && (
                  <NettingInvoiceEntries
                    nettingPaymentEntries={invoice.nettingPaymentEntries}
                  />
                )}
              </>
            ),
            expandIconColumnIndex: tableColumns.length,
            expandIcon: ({ expanded, onExpand, record }) => (
              <>
                {expanded ? (
                  <CustomButton
                    color="primary"
                    type="primary"
                    shape="round"
                    toolTipKey="payment.action.showPayments"
                    onClick={(e) => onExpand(record, e)}
                  >
                    {t('payment.button.hide')}
                  </CustomButton>
                ) : (
                  <CustomButton
                    color="secondary"
                    type="primary"
                    shape="round"
                    toolTipKey="payment.action.showPayments"
                    onClick={(e) => onExpand(record, e)}
                  >
                    {t('payment.button.show')}
                  </CustomButton>
                )}
              </>
            ),
          }}
        />
      </div>
    </Skeleton>
  );
};
