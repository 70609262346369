import { Accent, CmsTooltip } from '@/lib/components';
import { DateFilter } from '@/lib/components/datepickerFilter/dateFilter';
import { debounce } from '@/lib/utils/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Input, Row, Select, Switch } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceFilterOptions } from '../../../lib/types/invoices';
import { DownloadZipButton } from './downloadZip';
import styles from './styles.module.scss';

const { Option } = Select;

interface DossierFilterProps {
  zipInvoiceIds: number[];
  filterOptions: InvoiceFilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<InvoiceFilterOptions>>;
}

export const DossierFilter: FC<DossierFilterProps> = ({
  filterOptions,
  zipInvoiceIds,
  setFilterOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row
        gutter={20}
        style={{ margin: 0 }}
        align="bottom"
        className={styles.filterRow}
      >
        <Col md={6}>
          <CmsTooltip toolTipKey="dossier.label.invoiceType">
            <Accent color="secondary" type="p">
              {t('dossier.type')}
            </Accent>
          </CmsTooltip>
          <Select
            className={styles.select}
            value={filterOptions.type}
            style={{
              fontSize: '1.4rem',
            }}
            onChange={(type) =>
              setFilterOptions((prev) => ({ ...prev, page: 1, type }))
            }
            bordered={false}
            suffixIcon={
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                size="lg"
                className={styles.select}
              />
            }
          >
            <Option value="all">Inkoop/Verkoop</Option>
            <Option value="purchase">Inkoop</Option>
            <Option value="sales">Verkoop</Option>
          </Select>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <DownloadZipButton size="small" invoices={zipInvoiceIds} />
        </Col>
      </Row>
      <Row
        gutter={20}
        style={{ margin: 0, marginTop: '1rem' }}
        className={styles.filterRow}
        align="middle"
        justify="space-between"
      >
        <Col span={12}>
          <CmsTooltip toolTipKey="dossier.label.searchTerm">
            <Input
              bordered={false}
              className={styles.search}
              placeholder={t('dossier.searchBarPlaceholder')}
              onChange={debounce(
                (e) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    page: 1,
                    terms: e.target.value,
                  })),
                300,
              )}
            />
          </CmsTooltip>
        </Col>
        <Col>
          <CmsTooltip toolTipKey="dossier.label.archive">
            <Accent type="span" inline>
              {t('dossier.archiveSwitch')}
              <Switch
                style={{ marginLeft: '5px' }}
                size="small"
                checked={filterOptions.archived}
                onChange={(archived) =>
                  setFilterOptions((prev) => ({ ...prev, page: 1, archived }))
                }
                defaultChecked={false}
              />
            </Accent>
          </CmsTooltip>
        </Col>
      </Row>
      <Row
        style={{ margin: '1rem 0' }}
        align="bottom"
        className={styles.filterRow}
      >
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        ></DateFilter>
      </Row>
    </>
  );
};
