import { payableInvoiceCountAtom } from '@/lib/atoms/atoms';
import { CmsTooltip } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, MenuProps, Space } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
interface IHeaderMenu {
  mode?: 'horizontal' | 'vertical' | 'inline';
  iconOnly: boolean;
}

export const HeaderMenu = ({ mode, iconOnly }: IHeaderMenu) => {
  const props = useHeaderMenu(iconOnly);

  return <Menu mode={mode} {...props} />;
};

const useHeaderMenu: (iconOnly: boolean) => MenuProps = (iconOnly) => {
  const location = useLocation();
  const { relation } = useRelationContext();
  const { t } = useTranslation();
  const payableInvoiceCount = useAtomValue(payableInvoiceCountAtom);
  return {
    selectable: false,
    selectedKeys: location.pathname.split('/'),
    overflowedIndicator: <FontAwesomeIcon icon="bars" />,
    defaultSelectedKeys: [location.pathname],
    activeKey: '',
    items: [
      {
        key: 'invoices',
        disabled: !relation,
        label: (
          <CmsTooltip toolTipKey="menu.label.invoices">
            <Link to="/invoices">
              <Space>
                <FontAwesomeIcon icon="file-alt" />
                {!iconOnly ? t('nav.invoices') : null}
              </Space>
            </Link>
          </CmsTooltip>
        ),
      },
      {
        key: 'dossier',
        disabled: !relation,
        label: (
          <CmsTooltip toolTipKey="menu.label.dossier">
            <Link to="/dossier">
              <Space>
                <FontAwesomeIcon icon={['far', 'folder']} />
                {!iconOnly ? t('nav.dossier') : null}
              </Space>
            </Link>
          </CmsTooltip>
        ),
      },
      {
        key: 'payment',
        disabled: !relation,
        label: (
          <CmsTooltip toolTipKey="menu.label.payments">
            <Link to="/payment/inpayment">
              <Space>
                <FontAwesomeIcon icon={['far', 'credit-card']} />
                {!iconOnly ? t('label.payment_plural') : null}
                <Badge offset={[0, -20]} count={payableInvoiceCount} />
              </Space>
            </Link>
          </CmsTooltip>
        ),
      },
      {
        key: 'documents',
        disabled: !relation,
        label: (
          <CmsTooltip toolTipKey="menu.label.documents">
            <Link to="/documents">
              <Space>
                <FontAwesomeIcon icon={['far', 'file']} />
                {!iconOnly ? t('nav.documents') : null}
              </Space>
            </Link>
          </CmsTooltip>
        ),
      },
    ],
  };
};
