import { deleteInvoice } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton, GenericModal } from '@/lib/components';
import { Invoice } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Switch } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteButtonProps = {
  invoiceId: number;
  onSuccess?: (invoice: Invoice) => void;
};

export const DeleteButton = ({ invoiceId, onSuccess }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [isVisible, setIsVisible] = useState(false);
  const [deleteLinkedFiles, setDeleteLinkedFiles] = useState(false);

  const handleDelete = async () => {
    try {
      const invoice = await deleteInvoice(invoiceId, deleteLinkedFiles);
      showNotification('success', 'Factuur verwijderd.');
      onSuccess?.(invoice);
      setDeleteLinkedFiles(false);
    } catch {
      showNotification('error', 'Fout tijdens verwijderen factuur.');
    }
  };

  return !isAdmin ? null : (
    <>
      <GenericModal
        title={t('action.delete.invoice.title')}
        okText={t('action.delete.invoice.ok')}
        isVisible={isVisible}
        hideModal={() => setIsVisible(false)}
        onOkHandler={handleDelete}
        destroyOnClose
      >
        <p>{t('action.delete.invoice.content')}</p>

        <Space>
          <span>{t('action.delete.invoice.contentLinked')}</span>
          <Switch
            checked={deleteLinkedFiles}
            onChange={(e) => setDeleteLinkedFiles(e)}
          />
        </Space>
      </GenericModal>
      <CustomButton
        shape="circle"
        type="link"
        disabled={!isAdmin}
        color="danger"
        onClick={() => setIsVisible(true)}
        icon={<FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />}
        style={{ marginRight: 5 }}
        toolTipKey="invoiceDetail.action.delete"
      />
    </>
  );
};
