import { QueryClient, UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import { apiBasePaths } from "../_apiPaths";
import { AutoHandleRelation } from "@/lib/types/autoHandleRelation";
import { api } from '@/lib/utils/api.axios';
import { toQueryParams } from "@/lib/utils/array-utils";

const BASE_URL = `${apiBasePaths.invoices}/v2/autoHandlers`;
const QUERY_KEY = ['autoHandlers'];

export const invalidateAutoHandlersCache = async (
  client: QueryClient,
  counterRelationIds?: string[]
) => {
  await client.invalidateQueries({ queryKey: [...QUERY_KEY, counterRelationIds] });
}

export const useGetAutoHandlers = (
  counterRelationIds?: string[],
): UseQueryResult<AutoHandleRelation[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, counterRelationIds],
    queryFn: () =>
      api
        .post<AutoHandleRelation[]>(`${BASE_URL}`, counterRelationIds || [])
        .then((r) => r.data),
  });

export const useSetAutoHandler = (
  relationId: string
) =>
  useMutation<unknown, Error, { autoHandle: boolean, autoPayment?: boolean }>({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      autoHandle,
      autoPayment = false
    }) =>
      api
        .put(`${BASE_URL}/${relationId}`, null, {
          params: {
            autoHandle,
            autoPayment
          }
        })
        .then((r) => r.data),
  });