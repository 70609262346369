import { CustomButton, CustomColumnType, CustomTable } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import {
  useCreateSupportTicket,
  useGetSupportTickets,
} from '@/lib/queries/user/supportTickets';
import { SortOrder, SupportTicketFilter } from '@/lib/types';
import {
  SupportTicket,
  SupportTicketModifyRequest,
} from '@/lib/types/supportTicket';
import { phoneRegex } from '@/lib/utils/regex';
import { showNotification } from '@/lib/utils/showNotification';
import { UserAvatar } from '@/routes/_components/userAvatar';
import { Divider, Form, Input, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SupportTickets = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { mutateAsync: createItem } = useCreateSupportTicket();
  const [submitting, setSubmitting] = useState(false);
  const { relation } = useRelationContext();

  const [tableSettings] = useState<SupportTicketFilter>({
    orderField: 'created',
    order: SortOrder.Descending,
  });

  const { data, isLoading } = useGetSupportTickets(tableSettings);

  const columns: CustomColumnType<SupportTicket>[] = [
    {
      title: t('pages.settings.support.supportTickets.table.id'),
      key: 'topdeskNumber',
      dataIndex: 'topdeskNumber',
      width: 400,
    },
    {
      title: t('pages.settings.support.supportTickets.table.subject'),
      key: 'title',
      dataIndex: 'title',
      width: 400,
    },
    {
      title: t('pages.settings.support.supportTickets.table.creationDate'),
      key: 'created',
      dataIndex: 'created',
      width: 400,
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: t('pages.settings.support.supportTickets.table.status'),
      key: 'status',
      dataIndex: 'status',
      render: () => {
        return 'Succesvol aangemaakt';
      },
    },
  ];

  const onFinish = async (values) => {
    setSubmitting(true);
    try {
      const supportTicket: SupportTicketModifyRequest = {
        phonenumber: values.phonenumber,
        subject: values.subject,
        request: values.request,
      };
      await createItem(supportTicket);
      showNotification(
        'success',
        t('pages.settings.support.supportTickets.notificationSuccess'),
      );
      form.resetFields();
    } catch {
      showNotification(
        'error',
        t('pages.settings.support.supportTickets.notificationError'),
      );
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <p>{t('pages.settings.support.supportTickets.introduction')}</p>
        <p>{t('pages.settings.support.supportTickets.currentUser')}</p>
        <UserAvatar relation={relation} />
        <Form form={form} onFinish={onFinish} layout="vertical">
          <br />
          <Form.Item
            label={t('pages.settings.support.supportTickets.phonenumber')}
            name="phonenumber"
            style={{ width: 500 }}
            rules={[
              {
                required: true,
                pattern: phoneRegex,
                message: t(
                  'pages.settings.support.supportTickets.wrongPhonenumber',
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.support.supportTickets.phonenumberPlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.settings.support.supportTickets.subject')}
            name="subject"
            style={{ width: 500 }}
            rules={[
              {
                required: true,
                max: 80,
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.support.supportTickets.subjectPlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.settings.support.supportTickets.message')}
            name="request"
            style={{ width: 800 }}
            rules={[{ required: true }]}
            help={t('pages.settings.support.supportTickets.required')}
          >
            <TextArea
              placeholder={t(
                'pages.settings.support.supportTickets.messagePlaceholder',
              )}
            />
          </Form.Item>
          <br />
          <CustomButton
            loading={submitting}
            htmlType="submit"
            type="primary"
            color="secondary"
          >
            {t('pages.settings.support.supportTickets.submit')}
          </CustomButton>
        </Form>

        <Divider />
        <CustomTable
          rowKey="id"
          columns={columns}
          dataSource={data?.data}
        ></CustomTable>
      </Spin>
    </>
  );
};
