import { Col, Row } from 'antd';
import { Accent } from '../accent';

interface DetailItemProps {
  name: React.ReactNode;
  value: React.ReactNode;
  extra?: React.ReactNode;
  labelSpan?: number;
}

export const DetailItem: React.FunctionComponent<DetailItemProps> = ({
  name,
  value,
  extra,
  labelSpan,
}) => {
  return (
    <Row gutter={4}>
      <Col span={labelSpan || 10}>
        <Accent color="secondary">{name}:</Accent>
      </Col>
      <Col lg={extra ? labelSpan : 14}>
        <span>{value}</span>
      </Col>
      {extra && <Col flex={1}>{extra}</Col>}
    </Row>
  );
};
