import { appNameAtom, hasAccessAtom, userTokenParsed } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { useAppSettings, useLoginRegistration } from '@/lib/hooks';
import { setTheme } from '@/lib/utils/setTheme';
import { Skeleton } from 'antd';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { PropsWithChildren, useEffect } from 'react';
import { BlockedWrapper } from './blockedWrapper';
import { UserVerification } from './userVerification';

interface Theme {
  [key: string]: {
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
      logo: string;
      danger: string;
      success: string;
    };
    appName: string;
  };
}

const THEMES: Theme = {
  mijnalfaV1: {
    colors: {
      secondary: '#949D9E',
      primary: '#EAAB00',
      tertiary: '#1e1e1e',
      logo: '#1e1e1e',
      danger: '#ff4d4f',
      success: '#44bf40',
    },
    appName: 'Mijn Alfa',
  },
  mijnalfa: {
    colors: {
      primary: '#3AB5A7',
      secondary: '#4E2680',
      tertiary: '#575756',
      logo: '#575756',
      danger: '#ff4d4f',
      success: '#3AB5A7',
    },
    appName: 'Mijn Alfa',
  },
};

export const InitWrapper: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const setAppName = useUpdateAtom(appNameAtom);
  const {
    relation,
    setRelationId,
    isLoading: relationIsLoading,
  } = useRelationContext();
  const userToken = useAtomValue(userTokenParsed);
  const hasAccess = useAtomValue(hasAccessAtom);

  useLoginRegistration();
  const isLoading = useAppSettings();

  useEffect(() => {
    const THEME = THEMES[userToken.theme || 'mijnalfa'];
    setTheme(THEME.colors, 'Poppins');
    setAppName(THEME.appName);
  }, [userToken, setAppName]);

  useEffect(() => {
    if (userToken.relation_id && hasAccess.isCustomer) {
      setRelationId(userToken.relation_id);
    }
  }, [userToken.relation_id]);

  const isVerified = userToken.relation_id && userToken.user_id;
  if (!isVerified) {
    return <UserVerification />;
  }

  return (
    <Skeleton loading={isLoading || relationIsLoading}>
      <BlockedWrapper
        isFrozen={relation?.isFrozen}
        isBlocked={relation?.isBlocked}
      >
        {children}
      </BlockedWrapper>
    </Skeleton>
  );
};
