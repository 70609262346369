import {
  delete2FaCredentials,
  deleteUser,
  getRelations,
  getRolesEnum,
  getUsers,
  postUser,
  putUser,
  updateUserRelation,
} from '@/lib/adapters/users-adapter';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  GenericModal,
} from '@/lib/components';
import { RelationType, UserRoles } from '@/lib/types/enums';
import { Relation, User } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Modal, Row, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditUser } from './_components/addEditUser';
import { ExtraAccountantRelations } from './_components/extraAccountantRelations';

enum UserTask {
  Add,
  Edit,
  ManageAccountantRelations,
}

export const Users = () => {
  const { t } = useTranslation();

  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [userTask, setUserTask] = useState<UserTask>();
  const {
    data: users,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });

  const { data: accountantRelations } = useQuery({
    queryKey: ['relations', RelationType.Accountant],
    queryFn: () =>
      getRelations({ type: RelationType.Accountant }).then((x) => x.data),
    initialData: [],
  });

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getRolesEnum(),
    initialData: [],
  });

  const userRelationMutation = useMutation({
    mutationFn: ({
      userId,
      relations,
    }: {
      userId: string;
      relations: Relation[];
    }) =>
      updateUserRelation(
        userId,
        relations.map((x) => x.id),
      ),
  });

  const openUserModal = (task: UserTask, user?: User) => {
    setUserTask(task);
    setSelectedUser(user);
    setisModalVisible(true);
  };

  const showDelete = (id: string) => {
    Modal.confirm({
      title: t('user.actions.delete.title'),
      content: t('user.actions.delete.content'),
      okText: t('user.actions.delete.ok'),
      cancelText: t('general.actions.cancel'),
      okButtonProps: {
        shape: 'round',
      },
      cancelButtonProps: {
        shape: 'round',
      },
      onOk: async () => {
        try {
          await deleteUser(id);
          showNotification('success', 'Gebruiker verwijderd.');
          await refetch();
        } catch {
          showNotification('error', 'Fout tijdens verwijderen Gebruiker.');
        }
      },
      onCancel() {},
    });
  };

  const showReset2fa = (id: string) => {
    Modal.confirm({
      title: t('user.actions.reset2fa.title'),
      content: t('user.actions.reset2fa.content'),
      okText: t('user.actions.reset2fa.ok'),
      okButtonProps: {
        shape: 'round',
      },
      cancelButtonProps: {
        shape: 'round',
      },
      cancelText: t('general.actions.cancel'),
      onOk: async () => {
        try {
          await delete2FaCredentials(id);
          showNotification('success', '2FA gegevens gereset.');
          await refetch();
        } catch (e: any) {
          if (e?.status === 422) {
            showNotification('error', e?.message);
          } else {
            showNotification('error', 'Fout tijdens resetten gegevens.');
          }
        }
      },
      onCancel() {},
    });
  };

  const tableColumns: CustomColumnType<User>[] = [
    {
      title: t('user.username'),
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: t('user.email'),
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t('user.relation'),
      dataIndex: 'relation',
      enableDefaultSorter: false,
      sorter: (a, b) => a.relation.name.localeCompare(b.relation.name),
      render: (relation: Relation) => relation?.name,
      onFilter: (value, record) => {
        if (!value) {
          return true;
        }
        if (!record) {
          return false;
        }
        const relationName = record.relation?.name?.toLowerCase();
        return relationName.includes(value.toString().toLowerCase());
      },
    },
    {
      title: t('user.account-manager'),
      dataIndex: 'roles',
      key: 'roles-account-manager',
      enableDefaultSorter: false,
      enableDefaultSearch: false,
      filters: [{ text: 'Filter', value: true }],
      onFilter: (value, record) =>
        record.roles.includes(UserRoles.ACCOUNT_MANAGER) && value === true,
      sorter: (a, b) =>
        (b.roles.includes(UserRoles.ACCOUNT_MANAGER) ? 1 : 0) -
        (a.roles.includes(UserRoles.ACCOUNT_MANAGER) ? 1 : 0),
      render: (roles: string[]) =>
        roles.includes(UserRoles.ACCOUNT_MANAGER) ? (
          <FontAwesomeIcon icon="check" color="green" />
        ) : (
          <FontAwesomeIcon icon="times" color="red" />
        ),
    },
    {
      title: t('user.app-admin'),
      dataIndex: 'roles',
      key: 'roles-app-admin',
      enableDefaultSorter: false,
      enableDefaultSearch: false,
      filters: [{ text: 'Filter', value: true }],
      onFilter: (value, record) =>
        record.roles.includes(UserRoles.APP_ADMIN) && value === true,
      sorter: (a, b) =>
        (b.roles.includes(UserRoles.APP_ADMIN) ? 1 : 0) -
        (a.roles.includes(UserRoles.APP_ADMIN) ? 1 : 0),
      render: (roles: string[]) =>
        roles.includes(UserRoles.APP_ADMIN) ? (
          <FontAwesomeIcon icon="check" color="green" />
        ) : (
          <FontAwesomeIcon icon="times" color="red" />
        ),
    },
    {
      title: t('user.extra-customer-relations.relations'),
      dataIndex: 'relations',
      enableDefaultSorter: false,
      sorter: (a, b) => a.relations.length - b.relations.length,
      onFilter: (value, record) => {
        if (value === undefined) {
          return true;
        }
        if (!record) {
          return false;
        }
        return (
          record.relations.filter((x) => x.id !== record.relationId).length ===
          parseInt(value as string)
        );
      },
      render: (relations: Relation[], user) =>
        relations.filter((x) => x.id !== user.relationId).length,
    },
    {
      dataIndex: 'id',
      render: (id, user) => (
        <>
          <CustomButton
            type="link"
            onClick={() => openUserModal(UserTask.Edit, user)}
            icon={<FontAwesomeIcon icon="pencil-alt" />}
            toolTipKey="user.action.edit"
          />
          {user?.roles?.includes(UserRoles.ACCOUNT_MANAGER) &&
            !user?.roles?.includes(UserRoles.APP_ADMIN) && (
              <CustomButton
                shape="circle"
                type="ghost"
                onClick={() =>
                  openUserModal(UserTask.ManageAccountantRelations, user)
                }
                icon={<FontAwesomeIcon icon={['far', 'building']} size="sm" />}
                style={{ marginRight: 5 }}
                toolTipKey="user.action.manage-relations"
              />
            )}

          <CustomButton
            shape="circle"
            type="ghost"
            onClick={() => showReset2fa(id)}
            icon={
              <div className="fa-1x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={['fas', 'user-shield']} />
                  <FontAwesomeIcon
                    color="red"
                    transform="shrink-6 up-8 right-8"
                    icon={['fas', 'redo-alt']}
                  />
                </span>
              </div>
            }
            style={{ marginRight: 5 }}
            toolTipKey="user.action.reset2fa"
          />
          <CustomButton
            shape="circle"
            type="link"
            danger
            onClick={() => showDelete(id)}
            icon={<FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />}
            style={{ marginRight: 5 }}
            toolTipKey="user.action.delete"
          />
        </>
      ),
    },
  ];

  const onCreate = async (user: User, relations: Relation[]) => {
    const addedUser = await postUser(user);
    if (!addedUser.roles || !addedUser.roles.length) {
      await userRelationMutation.mutateAsync({
        userId: addedUser.id,
        relations,
      });
    }
    onModalClose();
    await refetch();
  };

  const onEdit = async (user: User, relations: Relation[]) => {
    if (!user.roles || !user.roles.length) {
      await userRelationMutation.mutateAsync({ userId: user.id, relations });
    }
    await putUser(user.id, user);
    onModalClose();
    await refetch();
  };

  const onExtraRelations = async (user: User) => {
    await userRelationMutation.mutateAsync({
      userId: user.id,
      relations: user.relations,
    });
    onModalClose();
  };

  const onModalClose = () => {
    setUserTask(undefined);
    setSelectedUser(undefined);
    setisModalVisible(false);
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="end">
          <Col>
            <CustomButton
              type="primary"
              color="secondary"
              toolTipKey="user.action.add"
              onClick={() => openUserModal(UserTask.Add)}
            >
              {t('user.actions.add')}
            </CustomButton>
          </Col>
        </Row>
        <CustomTable
          rowKey="id"
          loading={isFetching}
          columns={tableColumns}
          dataSource={users}
          pagination={{ hideOnSinglePage: true }}
          size="small"
        />
      </Space>

      <GenericModal
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: 400,
        }}
        isVisible={isModalVisible}
        hideModal={() => setisModalVisible(false)}
        onCancelHandler={onModalClose}
        footer={false}
        destroyOnClose
        title={
          selectedUser
            ? t('settings.users.labels.editUser')
            : t('settings.users.labels.addUser')
        }
      >
        {userTask !== undefined
          ? {
              [UserTask.Add]: (
                <AddEditUser
                  onFinish={onCreate}
                  onModalClose={onModalClose}
                  loading={isFetching}
                  roles={roles}
                />
              ),
              [UserTask.Edit]: selectedUser && (
                <AddEditUser
                  onFinish={onEdit}
                  onModalClose={onModalClose}
                  loading={isFetching}
                  user={selectedUser}
                  roles={roles}
                />
              ),
              [UserTask.ManageAccountantRelations]: (
                <ExtraAccountantRelations
                  onFinish={onExtraRelations}
                  onModalClose={onModalClose}
                  loading={userRelationMutation.isPending}
                  user={selectedUser}
                  relations={accountantRelations}
                />
              ),
            }[userTask]
          : null}
      </GenericModal>
    </>
  );
};
