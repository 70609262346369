import { CmsTooltip } from '@/lib/components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FC } from 'react';
import styles from './styles.module.scss';

export type CustomButtonProps = ButtonProps & {
  toolTipKey?: string;
  iconOnly?: boolean;
  disableToolTip?: boolean;
};

export const CustomButton: FC<CustomButtonProps> = ({
  color = 'primary',
  iconOnly = false,
  children,
  shape,
  toolTipKey,
  disableToolTip,
  ghost,
  type,
  danger,
  className,
  ...rest
}) => {
  const classes: string[] = [styles.button, styles[color]];
  if (ghost || type === 'ghost') {
    classes.push(styles.ghost);
  }

  if (danger) {
    classes.push(styles.danger);
  }

  if (type === 'link') {
    classes.push(styles.link);
  }
  if (className) {
    classes.push(className);
  }

  const childComp = (
    <Button
      shape={shape ?? (iconOnly ? 'circle' : 'round')}
      className={classes.join(' ')}
      {...rest}
    >
      {!iconOnly && children}
    </Button>
  );

  return disableToolTip ? (
    childComp
  ) : (
    <CmsTooltip toolTipKey={toolTipKey}>{childComp}</CmsTooltip>
  );
};
