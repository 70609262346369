import { getLocations } from '@/lib/adapters/users-adapter';
import { SingleTask, SingleTaskModifyRequest } from '@/lib/types/scheduler';
import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Input, Row, Select, Spin, Switch } from 'antd';
import dayjs from 'dayjs';

import { CustomButton, GenericModal, TextEditor } from '@/lib/components';
import { SingleTaskState, SingleTaskType } from '@/lib/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface TaskModalProps {
  loading: boolean;
  task?: SingleTask;
  onFinish: (task: SingleTaskModifyRequest) => void;
  onModalClose: () => void;
  setModalVisible: (visible: boolean) => void;
  isModalVisible: boolean;
}
export const TaskModal = ({
  loading,
  task,
  onFinish,
  onModalClose,
  setModalVisible,
  isModalVisible,
}: TaskModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isLoading, data: locations } = useQuery({
    queryKey: ['locations'],
    queryFn: () => getLocations(),
    retry: false,
  });

  useEffect(() => {
    form.resetFields();
    const updatedTask = {
      ...task,
      executionTimestamp: dayjs.utc(task?.executionTimestamp).local(),
    };
    form.setFieldsValue(updatedTask);
  }, [form, task]);

  const readonly =
    task?.state === SingleTaskState.Failed ||
    task?.state === SingleTaskState.Success;
  return (
    <GenericModal
      width="800px"
      isVisible={isModalVisible}
      hideModal={() => setModalVisible(false)}
      onCancelHandler={onModalClose}
      footer={false}
      destroyOnClose
      title={
        task
          ? t('settings.page.notifyusers.overview.actions.update')
          : t('settings.page.notifyusers.overview.actions.add')
      }
    >
      <Spin spinning={isLoading}>
        <Form
          disabled={readonly}
          onFinish={(values) => onFinish({ ...task, ...values })}
          form={form}
          labelCol={{ span: 4 }}
        >
          <Form.Item
            name="name"
            label={t('settings.page.notifyusers.overview.columns.name')}
            rules={[
              {
                required: true,
                message: t('settings.page.notifyusers.modify.validations.name'),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="type"
            label={t('settings.page.notifyusers.overview.columns.type')}
          >
            <Select
              defaultValue={SingleTaskType.NotifyUsersViaEmail}
              options={[
                {
                  label: 'Email notificatie',
                  value: SingleTaskType.NotifyUsersViaEmail,
                },
                {
                  label: 'MijnAlfa notificatie',
                  value: SingleTaskType.NotifyUsersInApp,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('settings.page.notifyusers.overview.columns.description')}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="directExecution"
            label={t(
              'settings.page.notifyusers.overview.columns.directExecution',
            )}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.directExecution !== curValues.directExecution
            }
          >
            {() => {
              const isChecked = form.getFieldValue('directExecution');
              if (isChecked) {
                return;
              }
              return (
                <Form.Item
                  name="executionTimestamp"
                  label={t(
                    'settings.page.notifyusers.overview.columns.executionTimestamp',
                  )}
                >
                  <DatePicker
                    showTime
                    format={`${dayjs.Ls.nl.formats.L} ${dayjs.Ls.nl.formats.LTS}`}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name="subject"
            label={t('settings.page.notifyusers.overview.columns.subject')}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="locations"
            label={t('settings.page.notifyusers.overview.columns.locations')}
            help={t('settings.page.notifyusers.modify.help.locations')}
          >
            <Select
              mode="multiple"
              filterOption={(input, option) => {
                if (!option?.children) return false;

                return (
                  option?.children
                    ?.toLocaleString()
                    ?.toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {locations &&
                locations.map((location) => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="message"
            label={t('settings.page.notifyusers.overview.columns.message')}
            rules={[
              {
                required: true,
                message: t(
                  'settings.page.notifyusers.modify.validations.message',
                ),
              },
            ]}
          >
            <TextEditor readonly={readonly} />
          </Form.Item>
          <Row justify="space-between">
            <CustomButton color="secondary" ghost onClick={onModalClose}>
              {t('general.actions.cancel')}
            </CustomButton>
            <CustomButton
              htmlType="submit"
              type="primary"
              color="secondary"
              loading={loading}
            >
              {t('general.actions.save')}
            </CustomButton>
          </Row>
        </Form>
      </Spin>
    </GenericModal>
  );
};
