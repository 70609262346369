import React from 'react';
import { Accent } from '../accent';

interface PageTitleProps {
  text?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4';
}
export const Heading: React.FunctionComponent<PageTitleProps> = ({
  text,
  type = 'h1',
}) => {
  return (
    <Accent type={type} color="secondary">
      {text}
    </Accent>
  );
};
