import { getLatestInvoice } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  AutoHandleButton,
  AutoPayButton,
  CustomButton,
  CustomColumnType,
  DocumentViewModal,
  EditSupplierModal,
  EditSupplierModalProps,
} from '@/lib/components';
import { PaymentSettingsTable } from '@/lib/components/tables';
import { useRelationContext } from '@/lib/context';
import { useGetPaymentSettings, useUpdatePaymentSetting } from '@/lib/queries';
import {
  invalidateAutoHandlersCache,
  useGetAutoHandlers,
} from '@/lib/queries/invoices/autoHandlers';
import { PaymentSetting, PaymentSettingTableEntry } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Divider } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RESET_MODALSTATE = {
  visible: false,
  loading: false,
};
export const SupplierSettings = () => {
  const { t } = useTranslation();

  const { mutateAsync: updatePaymentSetting } = useUpdatePaymentSetting();
  const { isLoading, data: paymentSettings } = useGetPaymentSettings();
  const { data: autoHandlers } = useGetAutoHandlers(
    paymentSettings.map((e) => e.counterRelationId),
  );
  const [tableRows, setTableRows] = useState<PaymentSettingTableEntry[]>([]);
  const client = useQueryClient();

  useEffect(() => {
    let autoHandle: string[] =
      autoHandlers?.map((e) => e.autoHandleRelationId) ?? [];
    let autoPayment: string[] =
      autoHandlers
        ?.filter((e) => e.isAutoPayment)
        .map((e) => e.autoHandleRelationId) ?? [];

    let rows =
      paymentSettings.map((e) => {
        return {
          ...e,
          autoHandle: autoHandle.includes(e.counterRelationId),
          autoHandlePayment: autoPayment.includes(e.counterRelationId),
        };
      }) ?? [];

    setTableRows(rows);
  }, [autoHandlers, paymentSettings]);

  const { isFrozen, relation } = useRelationContext();

  const hasAccess = useAtomValue(hasAccessAtom);

  const [modalState, setModalState] =
    useState<EditSupplierModalProps>(RESET_MODALSTATE);

  const onFormClose = () => setModalState(RESET_MODALSTATE);
  const onFormSuccess = async (paymentSetting: PaymentSetting) => {
    if (!modalState.paymentSetting?.id) return;

    const currentPaymentSetting = modalState.paymentSetting;
    const newPaymentSetting = {
      ...currentPaymentSetting,
      ...paymentSetting,
    };

    setModalState((prev) => ({ ...prev, loading: true }));
    try {
      await updatePaymentSetting({
        id: newPaymentSetting.id,
        request: newPaymentSetting,
      });
      setModalState(RESET_MODALSTATE);
      showNotification('success', 'Succesvol uitgevoerd');
    } catch (e) {
      setModalState((prev) => ({ ...prev, loading: false }));
    }
  };

  const showForm = (paymentSetting: PaymentSetting) =>
    setModalState({
      visible: true,
      loading: false,
      paymentSetting,
    });

  const getLatestProcessFileId = async (
    paymentSetting: PaymentSetting,
  ): Promise<string> => {
    const latestInvoice = await getLatestInvoice({
      counterRelationId: paymentSetting.counterRelationId,
    });
    if (!latestInvoice) {
      throw new Error('No invoice found');
    }
    return latestInvoice.sourceIdentifier;
  };

  const invalidateCachedAutoHandlers = () => {
    invalidateAutoHandlersCache(
      client,
      paymentSettings.map((e) => e.counterRelationId),
    );
  };

  const additionalColumns: CustomColumnType<PaymentSettingTableEntry>[] = [
    {
      title: '',
      align: 'right',
      render: (_, paymentSetting) => (
        <>
          <AutoHandleButton
            isAutoHandled={paymentSetting.autoHandle}
            relationId={paymentSetting.counterRelationId}
            onSuccess={invalidateCachedAutoHandlers}
            iconOnly
          />
          <AutoPayButton
            isAutoPaid={paymentSetting.autoHandlePayment}
            relationId={paymentSetting.counterRelationId}
            onSuccess={invalidateCachedAutoHandlers}
            iconOnly
          />
          <Divider type="vertical" />
          <DocumentViewModal
            id={() => getLatestProcessFileId(paymentSetting)}
            idType="processfile"
          />
          <CustomButton
            disabled={
              !(
                hasAccess.isCustomer ||
                (hasAccess.canImpersonate && relation)
              ) ||
              hasAccess.isAccountManager ||
              isFrozen
            }
            type="link"
            color="primary"
            shape="circle"
            onClick={() => showForm(paymentSetting)}
            toolTipKey="settingsMyNotaSuppliers.action.edit"
            icon={<FontAwesomeIcon icon="pencil-alt" />}
          />
        </>
      ),
      width: 180,
      enableDefaultSearch: false,
    },
  ];

  return (
    <>
      <PaymentSettingsTable
        isLoading={isLoading}
        additionalColumns={additionalColumns}
        items={tableRows}
        pagination={{ hideOnSinglePage: true }}
      />
      <EditSupplierModal
        {...modalState}
        onFormClose={onFormClose}
        onFormSuccess={onFormSuccess}
      />
    </>
  );
};
