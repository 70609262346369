import { Accent } from '@/lib/components';
import parse from 'html-react-parser';

import React, { PropsWithChildren } from 'react';
import { Heading } from '../core/typography/heading';

interface ContentPanelProps extends PropsWithChildren {
  title?: string;
  description?: string;
  style?: React.CSSProperties;
}

export const ContentPanel: React.FunctionComponent<ContentPanelProps> = ({
  title,
  description,
  children,
  style,
}) => {
  return (
    <div
      style={{
        padding: '30px',
        backgroundColor: 'white',
        overflowX: 'auto',
        ...style,
      }}
    >
      <Heading text={title} />

      <Accent type="p">{description && parse(description)}</Accent>
      {children}
    </div>
  );
};
