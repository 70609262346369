import {
  getNotificationSettings,
  setNotificationSettings,
} from '@/lib/adapters/notification-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Accent, CmsTooltip, CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { UINotificationSettings } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { Col, Form, Row, Select, Slider, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useAtomValue } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const settingMap = {
  0: [''],
  1: ['App'],
  2: ['App', 'Email'],
};

const excludedTypes: string[] = ['InvoiceUploaded'];

export const NotificationSettings: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [invoiceSettingsLoading, setinvoiceSettingsLoading] = useState(false);
  const { isFrozen, relation } = useRelationContext();

  const hasAccess = useAtomValue(hasAccessAtom);

  const [form] = Form.useForm();
  const threeMarks = {
    0: t('settings.tabs.customer.sliderMarks.1'),
    1: t('settings.tabs.customer.sliderMarks.2'),
    2: t('settings.tabs.customer.sliderMarks.3'),
  };
  useEffect(() => {
    setLoading(true);
    getNotificationSettings()
      .then((r) => {
        const mappedResult = r
          .filter((x) => !excludedTypes.includes(x.type))
          .map((x) => ({
            ...x,
            channels: x.channels.length,
          }));
        form.setFieldsValue({
          list: mappedResult,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveInvoiceSettings = (e: Store) => {
    setinvoiceSettingsLoading(true);
    const mappedList = e.list.map((x) => {
      const hasEmail = x.channels === 2;
      const emailSettings = hasEmail ? x.emailSettings : undefined;

      return {
        ...x,
        channels: settingMap[x.channels],
        emailSettings,
      };
    });
    setNotificationSettings(mappedList as UINotificationSettings[])
      .then((r) => {
        showNotification('success', 'Opgeslagen');
        setinvoiceSettingsLoading(false);
      })
      .catch(() => setinvoiceSettingsLoading(false));
  };
  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={saveInvoiceSettings}>
        <Row style={{ marginTop: '10px' }}>
          <Col span={7}>
            <Accent color="secondary" type="h3">
              {t('settings.tabs.customer.headers.type')}
            </Accent>
          </Col>
          <Col span={9}>
            <Accent color="secondary" type="h3">
              {t('settings.tabs.customer.headers.notification')}
            </Accent>
          </Col>
          <Col span={8}>
            <Accent color="secondary" type="h3">
              {t('settings.tabs.customer.headers.frequence')}
            </Accent>
          </Col>
        </Row>
        <Form.List name="list">
          {(fields) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  <Row style={{ marginTop: '10px' }}>
                    <Col span={7}>
                      <CmsTooltip
                        toolTipKey={`settingsMyNotaNotifications.${form.getFieldValue(
                          ['list', key, 'type'],
                        )}`}
                      >
                        {t(
                          `notificationTypes.${form.getFieldValue([
                            'list',
                            key,
                            'type',
                          ])}`,
                        )}
                      </CmsTooltip>
                    </Col>
                    <Col span={9}>
                      <Form.Item {...restField} name={[name, 'channels']}>
                        <Slider
                          disabled={isFrozen}
                          style={{ width: '80%' }}
                          tooltip={{ open: false }}
                          marks={threeMarks}
                          max={2}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item shouldUpdate>
                        {() => (
                          <Form.Item
                            {...restField}
                            name={[name, 'emailSettings', 'frequency']}
                          >
                            <Select
                              disabled={
                                form.getFieldValue([
                                  'list',
                                  key,
                                  'channels',
                                ]) !== 2 || isFrozen
                              }
                              style={{ width: '80%' }}
                            >
                              <Option value={'Daily'}>
                                {t(
                                  'settings.tabs.accountant.frequence.options.daily',
                                )}
                              </Option>
                              <Option value={'Weekly'}>
                                {t(
                                  'settings.tabs.accountant.frequence.options.weekly',
                                )}
                              </Option>
                              <Option value={'Monthly'}>
                                {t(
                                  'settings.tabs.accountant.frequence.options.monthly',
                                )}
                              </Option>
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <CustomButton
          loading={invoiceSettingsLoading}
          type="primary"
          shape="round"
          color="secondary"
          htmlType="submit"
          toolTipKey="settingsMyNotaNotifications.action.save"
          disabled={
            !(hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) ||
            hasAccess.isAccountManager ||
            isFrozen
          }
        >
          {t('general.actions.save')}
        </CustomButton>
      </Form>
    </Spin>
  );
};
