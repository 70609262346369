import { ControlledInput, CustomButton } from '@/lib/components';
import { useGetAllBanks } from '@/lib/queries';
import { Bank, PaymentSetting } from '@/lib/types';
import { percentageFormatter } from '@/lib/utils/formatters';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from 'antd';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { FC, Key } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Heading } from '../core/typography/heading';

export interface EditSupplierModalProps {
  visible: boolean;
  loading: boolean;
  paymentSetting?: PaymentSetting;
  onFormClose?: () => void;
  onFormSuccess?: (values: PaymentSetting) => void;
}

const getDefaultName = (amount: number) => {
  if (amount === 0) {
    return 'Betaalrekening';
  }

  if (amount === 1) {
    return 'G-rekening';
  }

  return `Betaalrekening ${amount}`;
};

export const EditSupplierModal: FC<EditSupplierModalProps> = ({
  visible,
  loading,
  paymentSetting,
  onFormClose,
  onFormSuccess,
}) => {
  const { isLoading, data: banks } = useGetAllBanks();

  const onFormFinish = (values: PaymentSetting) => {
    onFormSuccess?.(values);
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={visible}
      onCancel={onFormClose}
      title={t('settings.tabs.suppliers.labels.changeSupplier')}
      destroyOnClose
      footer={false}
      width={700}
      getContainer={false}
    >
      <EditForm
        paymentSetting={paymentSetting}
        onFormFinish={onFormFinish}
        loading={loading || isLoading}
        banks={banks}
      />
    </Modal>
  );
};

const DEFAULT_LAYOUT: FormItemProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

const EditForm = ({ paymentSetting, onFormFinish, loading, banks }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const getBicFromIban = (iban?: string) => {
    if (!iban || iban?.length < 8) {
      return;
    }
    const bankIdentifier = iban.substring(4, 8);
    const bic = banks?.find(
      (x: Bank) => x.bankIdentifier === bankIdentifier,
    )?.bic;

    return bic;
  };

  const handleIbanChange = (field: Key[], value?: string) => {
    const bic = getBicFromIban(value);
    if (!bic) {
      return;
    }

    form.setFieldValue(field, bic);
  };

  return (
    <Form
      name="editSupplier"
      initialValues={paymentSetting}
      form={form}
      preserve={false}
      onFinish={onFormFinish}
    >
      <Heading type="h4" text={t('settings.tabs.supplier.bankAccounts')} />
      <Form.List name="bankAccounts">
        {(fields, { add, remove }) => (
          <>
            <Row gutter={8}>
              <Col span={6}>
                <span>{t('settings.tabs.supplier.header.name')}</span>
              </Col>
              <Col span={10}>
                <span>{t('settings.tabs.supplier.header.iban')}</span>
              </Col>
              <Col span={4}>
                <span>{t('settings.tabs.supplier.header.bic')}</span>
              </Col>
              <Col span={2}>
                <span>{t('settings.tabs.supplier.header.isValidated')}</span>
              </Col>
            </Row>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} gutter={8}>
                <Col span={6}>
                  <Form.Item
                    {...DEFAULT_LAYOUT}
                    {...restField}
                    key={key}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Veld is verplicht' }]}
                  >
                    <Input
                      maxLength={25}
                      placeholder={t('settings.tabs.supplier.header.name')}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    {...DEFAULT_LAYOUT}
                    {...restField}
                    key={key}
                    name={[name, 'iban']}
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!value) {
                            throw new Error('Verplicht');
                          }
                          if (!isValidIBAN(value))
                            throw new Error('Ongeldige IBAN');
                        },
                      },
                    ]}
                  >
                    <ControlledInput
                      onBlur={(e) =>
                        handleIbanChange(
                          ['bankAccounts', name, 'bic'],
                          e.target.value,
                        )
                      }
                      placeholder={'IBAN'}
                      normalize={(value) => (value || '').toUpperCase()}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    shouldUpdate={(prev, next) =>
                      prev.bankAccounts[name]?.iban !==
                      next.bankAccounts[name]?.iban
                    }
                  >
                    {({ getFieldValue }) => {
                      const bic = getBicFromIban(
                        getFieldValue(['bankAccounts', name, 'iban']),
                      );

                      return (
                        <Form.Item
                          {...DEFAULT_LAYOUT}
                          {...restField}
                          name={[name, 'bic']}
                          rules={[
                            {
                              validator: async (rule, value) => {
                                if (!value) {
                                  return;
                                }
                                if (!isValidBIC(value))
                                  throw new Error('Ongeldige BIC');
                              },
                            },
                          ]}
                        >
                          {bic ? (
                            <Input defaultValue={bic} disabled />
                          ) : (
                            <ControlledInput
                              value={bic}
                              placeholder={'BIC'}
                              normalize={(value) => (value || '').toUpperCase()}
                            />
                          )}
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    {...DEFAULT_LAYOUT}
                    {...restField}
                    key={key}
                    name={[name, 'isValidated']}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Col>
                    <Form.Item {...restField}>
                      <CustomButton
                        ghost
                        danger
                        shape="circle"
                        size="small"
                        onClick={() => remove(name)}
                      >
                        <DeleteOutlined />
                      </CustomButton>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            ))}
            <Row>
              <CustomButton
                disabled={fields.length >= 10}
                ghost
                color="secondary"
                onClick={() =>
                  add({
                    name: getDefaultName(fields.length),
                  })
                }
              >
                {t('settings.tabs.supplier.addAccount')}
              </CustomButton>
            </Row>
          </>
        )}
      </Form.List>
      <div style={{ marginTop: '1rem' }}>
        <Heading type="h4" text={t('settings.tabs.supplier.configuration')} />

        <Form.Item
          {...DEFAULT_LAYOUT}
          name="paymentTerm"
          label={t('settings.tabs.supplier.header.paymentTerm')}
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          {...DEFAULT_LAYOUT}
          name="defaultDiscount"
          label={t('settings.tabs.supplier.header.defaultDiscount')}
          normalize={percentageFormatter().parse}
        >
          <NumericFormat
            className="ant-input"
            allowNegative={false}
            isAllowed={(value) =>
              !value.floatValue ||
              (value.floatValue >= 0 && value.floatValue <= 100)
            }
            onValueChange={({ value }) => value}
            decimalSeparator=","
            fixedDecimalScale={false}
            decimalScale={2}
            thousandSeparator="."
            suffix=" %"
          />
        </Form.Item>
      </div>
      <Row justify="space-between">
        <Col></Col>
        <CustomButton
          style={{ marginTop: '10px' }}
          htmlType="submit"
          type="primary"
          color="secondary"
          loading={loading}
        >
          {t('general.actions.save')}
        </CustomButton>
      </Row>
    </Form>
  );
};
