import { getLocations } from '@/lib/adapters/users-adapter';
import { Accent, CustomButton, TextEditor } from '@/lib/components';
import { LocationProps, NoFilesUploadedTrigger } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteNoUploadedFilesTrigger,
  getNoUploadedFilesTrigger,
  postNoUploadedFilesTrigger,
  putNoUploadedFilesTrigger,
} from '../../../../lib/adapters/scheduler-adapter';

const { Option } = Select;

interface TriggerForm {
  triggers: NoFilesUploadedTrigger[];
}

const daysOfMonth: number[] = [];
for (let i = 1; i <= 31; i++) {
  daysOfMonth.push(i);
}

const FREQUENCY = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
};

export const NoFilesUploaded: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const { t } = useTranslation();

  const fetchData = async () => {
    setLoading(true);
    const locations = await getLocations();
    setLocations(locations);
    const triggers = await getNoUploadedFilesTrigger();
    const converted = triggers.map((x) => ({
      ...x,
      timeUnit: {
        ...x.timeUnit,
        timeSpan: dayjs
          .utc(x.timeUnit.timeSpan, 'HH:mm:ss')
          .local()
          .format('HH:mm:ss'),
      },
    }));
    form.setFieldsValue({ triggers: converted });
    setLoading(false);
  };

  const normalize = (e: string): string => {
    return e.replaceAll('<p>', `<p style="margin: 0">`);
  };
  const normalizeTrigger = (
    trigger: NoFilesUploadedTrigger,
  ): NoFilesUploadedTrigger => {
    return {
      ...trigger,
      message: normalize(trigger.message),
      timeUnit: {
        ...trigger.timeUnit,
        timeSpan: dayjs(trigger.timeUnit.timeSpan, 'HH:mm')
          .utc()
          .format('HH:mm:ss'),
      },
    };
  };

  const submitTriggers = (formValues: TriggerForm) => {
    setSubmitting(true);

    const taskList: Promise<any>[] = [];

    formValues.triggers.forEach((trigger) => {
      const utcTrigger = normalizeTrigger(trigger);
      utcTrigger.locations = utcTrigger.locations || [];

      if (utcTrigger.id) {
        taskList.push(putNoUploadedFilesTrigger(utcTrigger.id, utcTrigger));
      } else {
        taskList.push(postNoUploadedFilesTrigger(utcTrigger));
      }
    });

    Promise.all(taskList)
      .then(() => {
        fetchData();
        showNotification('success', 'Triggers succesvol opgeslagen');
      })
      .catch(() =>
        notification.error({
          description: 'Er gaan wat fout tijdens het opslaan',
          message: 'Oops',
        }),
      )
      .finally(() => setSubmitting(false));
  };

  const deleteTrigger = (id: number) =>
    deleteNoUploadedFilesTrigger(id).then(() => fetchData());

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Accent color="secondary">
        {t('settings.tabs.accountant.subtitle')}
      </Accent>
      <Spin spinning={isLoading}>
        <Form
          labelCol={{ span: 6 }}
          labelAlign="left"
          form={form}
          onFinish={submitTriggers}
          layout="horizontal"
        >
          <Form.List name="triggers">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div
                    key={field.fieldKey}
                    style={{
                      padding: '15px',
                      backgroundColor: '#FBFBFB',
                      border: '2px solid #DCDCDC',
                      marginBottom: '1rem',
                    }}
                  >
                    <Row align="stretch">
                      <Col span={12}>
                        {t('settings.tabs.accountant.settings')}
                      </Col>
                      <Col
                        span={12}
                        style={{ textAlign: 'right', color: 'red' }}
                      >
                        <Form.Item shouldUpdate noStyle>
                          {() => {
                            const triggerId = form.getFieldValue([
                              'triggers',
                              field.name,
                              'id',
                            ]);
                            if (triggerId)
                              return (
                                <Popconfirm
                                  onConfirm={() => deleteTrigger(triggerId)}
                                  title="Weet je het zeker?"
                                  okButtonProps={{
                                    shape: 'round',
                                  }}
                                  cancelButtonProps={{
                                    shape: 'round',
                                  }}
                                >
                                  <FontAwesomeIcon icon="trash-alt" />
                                </Popconfirm>
                              );
                            return (
                              <FontAwesomeIcon
                                onClick={() => remove(field.name)}
                                icon="trash-alt"
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '15px' }} gutter={24}>
                      <Col span={12}>
                        <Form.Item hidden noStyle name={[field.name, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label={t('settings.tabs.accountant.frequence.title')}
                          name={[field.name, 'timeUnit', 'frequency']}
                          rules={[{ required: true }]}
                        >
                          <Select>
                            <Option value={FREQUENCY.DAILY}>
                              {t(
                                'settings.tabs.accountant.frequence.options.daily',
                              )}
                            </Option>
                            <Option value={FREQUENCY.WEEKLY}>
                              {t(
                                'settings.tabs.accountant.frequence.options.weekly',
                              )}
                            </Option>
                            <Option value={FREQUENCY.MONTHLY}>
                              {t(
                                'settings.tabs.accountant.frequence.options.monthly',
                              )}
                            </Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.triggers[field.name]?.timeUnit
                              ?.frequency !==
                            curValues.triggers[field.name]?.timeUnit?.frequency
                          }
                          noStyle
                        >
                          {() => {
                            const frequency = form.getFieldValue([
                              'triggers',
                              field.name,
                              'timeUnit',
                              'frequency',
                            ]);

                            if (frequency === FREQUENCY.WEEKLY)
                              return (
                                <Form.Item
                                  label={t('settings.tabs.accountant.dayLabel')}
                                  name={[field.name, 'timeUnit', 'dayOfWeek']}
                                  rules={[{ required: true }]}
                                >
                                  <Select>
                                    <Option value={1}>{t('days.1')}</Option>
                                    <Option value={2}>{t('days.2')}</Option>
                                    <Option value={3}>{t('days.3')}</Option>
                                    <Option value={4}>{t('days.4')}</Option>
                                    <Option value={5}>{t('days.5')}</Option>
                                    <Option value={6}>{t('days.6')}</Option>
                                    <Option value={0}>{t('days.0')}</Option>
                                  </Select>
                                </Form.Item>
                              );

                            if (frequency === FREQUENCY.MONTHLY)
                              return (
                                <Form.Item
                                  label={t(
                                    'settings.tabs.accountant.timeUnit.dayOfMonth',
                                  )}
                                  name={[field.name, 'timeUnit', 'dayOfMonth']}
                                  rules={[{ required: true }]}
                                >
                                  <Select>
                                    {daysOfMonth.map((day) => (
                                      <Option key={day} value={day}>
                                        {day}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              );
                            return;
                          }}
                        </Form.Item>
                        <Form.Item
                          label={t(
                            'settings.tabs.accountant.timeUnit.timeSpan',
                          )}
                          name={[field.name, 'timeUnit', 'timeSpan']}
                          rules={[{ required: true }]}
                        >
                          <Input type="time" />
                          {/* <TimePicker format="HH:mm" /> */}
                        </Form.Item>

                        <Form.Item
                          label={t('settings.tabs.accountant.dayCount')}
                          name={[field.name, 'maxDays']}
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          label={t('settings.tabs.accountant.locations')}
                          name={[field.name, 'locations']}
                          help={t('settings.tabs.accountant.locationInfo')}
                        >
                          <Select
                            mode="multiple"
                            style={{ width: '80%' }}
                            filterOption={(input, option) => {
                              if (!option?.children) return false;

                              return (
                                option?.children
                                  ?.toLocaleString()
                                  ?.toLowerCase()
                                  ?.indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {locations.map((location) => (
                              <Option key={location.id} value={location.id}>
                                {location.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t('settings.tabs.accountant.triggerEnabled')}
                          name={[field.name, 'enabled']}
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>

                      {/* RIGHT SIDE OF FORM */}
                      <Col span={12}>
                        <Form.Item
                          label={t('settings.tabs.accountant.message')}
                          name={[field.name, 'message']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter body of post',
                            },
                          ]}
                          style={{ height: '100%' }}
                          noStyle
                        >
                          <TextEditor />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    <Form.Item>
                      <CustomButton
                        color="secondary"
                        type="primary"
                        shape="round"
                        toolTipKey="triggers.action.addTrigger"
                        icon={<FontAwesomeIcon icon="plus" />}
                        onClick={() => add()}
                      >
                        {t('label.trigger')}
                      </CustomButton>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <CustomButton
                        color="secondary"
                        htmlType="submit"
                        type="primary"
                        shape="round"
                        loading={isSubmitting}
                        toolTipKey="triggers.action.saveTriggers"
                      >
                        {t('general.actions.save')}
                      </CustomButton>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Spin>
    </div>
  );
};
