import { setIsHandled } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { Invoice } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '../core/buttons/toggleButton';

type HandleButtonProps = {
  iconOnly?: boolean;
  isHandled: boolean;
  invoiceId: number;
  onSuccess?: (invoice: Invoice) => void;
};

export const HandleButton: FC<HandleButtonProps> = ({
  invoiceId,
  isHandled,
  iconOnly = true,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [hasAccess] = useAtom(hasAccessAtom);
  const [isLoading, setLoading] = useState(false);
  const { isFrozen } = useRelationContext();

  const onClick = async () => {
    setLoading(true);
    try {
      const invoice = await setIsHandled(invoiceId, isHandled);
      onSuccess?.(invoice);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ToggleButton
      active={!isHandled}
      type="primary"
      color="secondary"
      ghost
      disabled={hasAccess.isAccountManager || isFrozen}
      shape={iconOnly ? 'circle' : 'round'}
      icon={<FontAwesomeIcon icon={['far', 'folder']} size="sm" />}
      onClick={onClick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.handle"
    >
      {!iconOnly && (isHandled ? t('label.handle') : t('label.resetHandle'))}
    </ToggleButton>
  );
};
