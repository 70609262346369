import {
  getProcessFiles,
  rejectProcessFile,
  rejectProcessFileBatch,
  reprocessProcessFile,
  reprocessProcessFileBatch,
} from '@/lib/adapters/invoice-adapter';
import { getRelations } from '@/lib/adapters/users-adapter';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import { ScopeTrackingButton } from '@/lib/components/scope';
import {
  ProcessFile,
  ProcessFileFilter,
  ProcessFileWithOwner,
  SortOrder,
} from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UblDownloadButton } from '../../../lib/components/invoice-buttons/ublDownloadButton';
import { InvoiceRejectModal } from '../../../lib/components/reject-invoice/invoiceRejectModal';
import {
  RejectFile,
  RejectModalState,
} from '../../../lib/types/reject-invoice';

const baseColumnSettings: CustomColumnType<ProcessFileWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

const formatIdentifier = (identifier?: string, identifierType?: string) => {
  let str = '';

  if (identifier) {
    str += identifier;
  }
  if (identifierType) {
    str += `(${identifierType})`;
  }

  return str;
};

export const ProcessData = () => {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState<RejectModalState>('hidden');
  const [files, setFiles] = useState<ProcessFileWithOwner[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<ProcessFileWithOwner[]>(
    [],
  );
  const [currentFile, setCurrentFile] = useState<ProcessFileWithOwner>();
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [tableSettings, setTableSettings] = useState<ProcessFileFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'deliveryDate',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const reprocessSelected = async () => {
    try {
      setLoading(true);
      await reprocessProcessFileBatch(selectedFiles.map((x) => ({ id: x.id })));
      showNotification(
        'success',
        'Geselecteerde opnieuw ter verwerking gesteld.',
      );
      setSelectedFiles([]);
      fetchData(tableSettings);
    } catch (e) {
      showNotification('error', 'Fout tijdens opnieuw verwerken facturen');
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (tableSettings: ProcessFileFilter) => {
    setLoading(true);
    try {
      const files = await getProcessFiles({
        ...tableSettings,
        excludeStatusses: [
          'Success',
          'Duplicate',
          'MigrationError',
          'Rejected',
        ],
      });
      setTotal(files.total);

      const relationIds = Array.from(
        new Set(
          files.data
            .filter((x) => x.ownerRelationId)
            .map((x) => x.ownerRelationId),
        ),
      );

      const relations = relationIds.length
        ? (
            await getRelations({
              relationIds: relationIds,
            })
          )?.data
        : [];

      const filesWithOwner: ProcessFileWithOwner[] = files.data.map((x) => ({
        ...x,
        owner: relations.find((relation) => relation.id === x.ownerRelationId),
      }));
      setFiles(filesWithOwner);
    } catch (e) {
      console.log(e);
      showNotification('error', 'Fout tijdens ophalen facturen');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(tableSettings);
  }, [tableSettings]);

  const reprocessFile = async (id: string) => {
    try {
      await reprocessProcessFile(id);
      showNotification('success', 'Opnieuw ter verwerking gesteld.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens herverwerken factuur.');
    }
  };

  const rejectFile = async (
    file: RejectFile,
    reason: string,
    description?: string,
  ) => {
    try {
      await rejectProcessFile(file.id as string, reason, description);
      showNotification('success', 'Afgewezen.');
      setCurrentFile(undefined);
      fetchData(tableSettings);
      closeRejectModal();
    } catch {
      showNotification('error', 'Fout tijdens afwijzen.');
    }
  };

  const rejectBatch = async (
    files: RejectFile[],
    reason: string,
    description?: string,
  ) => {
    try {
      setLoading(true);
      await rejectProcessFileBatch(
        files.map((x) => ({ id: x.id as string, reason, description })),
      );
      showNotification('success', 'Geselecteerde facturen afgewezen');
      fetchData(tableSettings);
      setSelectedFiles([]);
      closeRejectModal();
    } catch (e) {
      showNotification('error', 'Fout tijdens afwijzen facturen');
    } finally {
      setLoading(false);
    }
  };

  const openSingleRejectModal = (file: ProcessFile) => {
    setCurrentFile(file);
    setModalState('single');
  };

  const openMultiRejectModal = () => {
    setModalState('multi');
  };

  const closeRejectModal = () => {
    setModalState('hidden');
  };

  const tableColumns: CustomColumnType<ProcessFile>[] = [
    {
      ...baseColumnSettings,
      title: t('processFile.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: ProcessFileWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.deliveryDate'),
      dataIndex: 'deliveryDate',
      width: 200,
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.documentName'),
      dataIndex: 'documentName',
      width: 300,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.uploadType'),
      dataIndex: 'uploadType',
      render: (value) => t(`uploadTypes.${value}`),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.status'),
      dataIndex: 'status',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.statusDescription'),
      dataIndex: 'statusDescription',
      render: (value) =>
        value
          ? t(`api.${value}`, { ns: 'errors', defaultValue: value })
          : undefined,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.customerIdentifier'),
      dataIndex: 'customerIdentifier',
      render: (_, record) =>
        formatIdentifier(
          record.customerIdentifier,
          record.customerIdentifierType,
        ),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.supplierIdentifier'),
      dataIndex: 'supplierIdentifier',
      render: (_, record) =>
        formatIdentifier(
          record.supplierIdentifier,
          record.supplierIdentifierType,
        ),
    },
    {
      width: 180,
      dataIndex: 'id',
      render: (id, row: ProcessFile) => (
        <>
          <DocumentViewModal id={id} idType="processfile" />
          <UblDownloadButton processFileId={id} />
          <ScopeTrackingButton scopeId={row.scopeId} />
          <Popconfirm
            title={t('processFile.actions.reprocess.content')}
            onConfirm={() => reprocessFile(id)}
            okButtonProps={{
              shape: 'round',
            }}
            cancelButtonProps={{
              shape: 'round',
            }}
          >
            <CustomButton
              toolTipKey="processData.action.reprocess"
              type="link"
              icon={<FontAwesomeIcon icon="redo" />}
            />
          </Popconfirm>
          <CustomButton
            type="link"
            onClick={() => openSingleRejectModal(row)}
            toolTipKey="processData.action.reject"
            danger
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon="ban" />}
          />
        </>
      ),
    },
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProcessFile[]) => {
      setSelectedFiles(selectedRows);
    },
    getCheckboxProps: (record: ProcessFile) => ({}),
    preserveSelectedRowKeys: false,
  };

  return (
    <>
      <InvoiceRejectModal
        modalState={modalState}
        hideModal={closeRejectModal}
        singleFile={
          currentFile
            ? {
                id: currentFile.id,
                name: currentFile?.documentName,
              }
            : undefined
        }
        multiFiles={selectedFiles.map((x) => ({
          id: x.id,
          name: x.documentName,
        }))}
        rejectSingle={rejectFile}
        rejectBatch={rejectBatch}
      />
      <Row>
        <Col flex={1}>
          <Row justify="end" gutter={16} align="middle">
            <Col>
              <span>
                {selectedFiles.length
                  ? `${selectedFiles.length} geselecteerd`
                  : null}
              </span>
            </Col>
            <Col>
              <CustomButton
                disabled={!selectedFiles.length}
                onClick={openMultiRejectModal}
                loading={isLoading}
                type="primary"
                toolTipKey="processData.action.reject"
                danger
                shape="round"
                icon={<FontAwesomeIcon icon="ban" />}
              />
              <Popconfirm
                title={t('processFile.actions.reprocess.contentAll')}
                okText={t('processFile.actions.reprocess.ok')}
                onConfirm={() => reprocessSelected()}
                okButtonProps={{
                  shape: 'round',
                }}
                cancelButtonProps={{
                  shape: 'round',
                }}
              >
                <CustomButton
                  disabled={!selectedFiles.length}
                  loading={isLoading}
                  toolTipKey="processData.action.reprocess"
                  type="primary"
                  color="secondary"
                  icon={<FontAwesomeIcon icon="redo" />}
                />
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomTable
        rowKey="id"
        rowSelection={rowSelection}
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        onChange={onTableChange}
        columns={tableColumns}
        dataSource={files}
        pagination={{
          current: tableSettings.page,
          pageSize: tableSettings.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: () => {},
        }}
        size="small"
      />
    </>
  );
};
