import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { Card, Col, Form, Input, Popconfirm, Row } from 'antd';

import {
  Accent,
  CustomButton,
  CustomColumnType,
  CustomTable,
  GenericModal,
} from '@/lib/components';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { LabelValue, LabelValueModifyRequest } from '@/lib/types/labels';
import { showNotification } from '@/lib/utils/showNotification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createLabelValue,
  deleteLabelValue,
  getLabelDetails,
  updateLabel,
  updateLabelValue,
} from '../../../../lib/adapters/label-adapter';
import { AddEditLabelValue } from './_components/addEditLabelValue';

export const LabelManagementDetails = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id!);
  const navigate = useNavigate();
  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<LabelValue>();

  const { isLoading, data, refetch, isRefetching } = useQuery({
    queryKey: ['labels', id],
    queryFn: () => getLabelDetails(id),
    retry: false,
    enabled: !!id,
  });

  const detailsFormFinish = async (values: {
    name: string;
    description?: string;
  }) => {
    try {
      await updateLabel(id, values);
      refetch();
    } catch (e: any) {
      const errorText =
        e.status === 409
          ? t('general.errors.conflict', {
              entity: 'Label',
              parameter: 'naam',
              value: values.name,
            })
          : t('general.errors.unknown');
      showNotification('error', errorText);
    }
  };

  const tableColumns: CustomColumnType<LabelValue>[] = [
    {
      title: t('settings.tabs.labelmanagementDetails.columns.id'),
      dataIndex: 'id',
    },
    {
      title: t('settings.tabs.labelmanagementDetails.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('settings.tabs.labelmanagementDetails.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('settings.tabs.labelmanagementDetails.columns.created'),
      dataIndex: 'created',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      enableDefaultSearch: false,
      enableDefaultSorter: false,
      render: (id, labelvalue) => (
        <>
          <CustomButton
            type="link"
            onClick={() => openModal(labelvalue)}
            icon={<FontAwesomeIcon icon="pencil-alt" />}
          />
          <Popconfirm
            title={t('general.actions.confirm')}
            onConfirm={() => onDelete(id)}
            okButtonProps={{
              shape: 'round',
            }}
            cancelButtonProps={{
              shape: 'round',
            }}
          >
            <CustomButton
              type="link"
              danger
              shape="circle"
              size="small"
              icon={<FontAwesomeIcon icon="trash-alt" />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const openModal = (label?: LabelValue) => {
    setSelectedValue(label);
    setisModalVisible(true);
  };

  const onModalClose = () => {
    setSelectedValue(undefined);
    setisModalVisible(false);
  };

  const onDelete = async (id: number) => {
    try {
      await deleteLabelValue(id);
      refetch();
    } catch (e: any) {
      showNotification('error', t('general.errors.unknown'));
    }
  };

  const onAddEditFinish = async (labelValue: LabelValueModifyRequest) => {
    try {
      if (selectedValue) {
        await updateLabelValue(selectedValue.id, labelValue);
      } else {
        await createLabelValue(id, labelValue);
      }
      onModalClose();
      refetch();
    } catch (e: any) {
      const errorText =
        e.status === 409
          ? t('general.errors.conflict', {
              entity: 'Waarde',
              parameter: 'naam',
              value: labelValue.name,
            })
          : t('general.errors.unknown');
      showNotification('error', errorText);
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col flex={1}>
          <CustomButton ghost color="secondary" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon="arrow-left" />
            <Accent>
              {t('settings.tabs.labelmanagementDetails.actions.back')}
            </Accent>
          </CustomButton>
        </Col>
        <Col>
          <CustomButton color="secondary" onClick={() => openModal()}>
            <FontAwesomeIcon icon="plus" />
            <Accent>
              {t('settings.tabs.labelmanagementDetails.actions.addValue')}
            </Accent>
          </CustomButton>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '8px' }}>
        <Col lg={6}>
          <Spinal initializing={isLoading} loading={isRefetching}>
            <Card>
              <Form
                disabled={isRefetching || isLoading}
                layout="vertical"
                onFinish={(values) => detailsFormFinish(values)}
                initialValues={data}
              >
                <Form.Item
                  label={t('settings.tabs.labelmanagementDetails.form.name')}
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t(
                    'settings.tabs.labelmanagementDetails.form.description',
                  )}
                  name="description"
                >
                  <Input />
                </Form.Item>
                <CustomButton
                  htmlType="submit"
                  type="primary"
                  color="secondary"
                  loading={isLoading}
                >
                  {t('settings.tabs.labelmanagementDetails.actions.save')}
                </CustomButton>
              </Form>
            </Card>
          </Spinal>
        </Col>
        <Col flex={1}>
          <CustomTable columns={tableColumns} dataSource={data?.values} />
        </Col>
      </Row>

      <GenericModal
        isVisible={isModalVisible}
        hideModal={() => setisModalVisible(false)}
        onCancelHandler={onModalClose}
        footer={false}
        destroyOnClose
        title={
          selectedValue
            ? t('settings.tabs.labelmanagementDetails.actions.editValue')
            : t('settings.tabs.labelmanagementDetails.actions.addValue')
        }
      >
        <AddEditLabelValue
          onFinish={onAddEditFinish}
          onModalClose={onModalClose}
          isLoading={isLoading}
          value={selectedValue}
        />
      </GenericModal>
    </div>
  );
};
