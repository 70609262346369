import { ControlledInput, CustomButton, GenericModal } from '@/lib/components';
import { useGetAllBanks } from '@/lib/queries';
import { Bank, BankAccountBase, PaymentSettingBankAccount } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { SelectOutlined } from '@ant-design/icons';
import {
  Form,
  FormItemProps,
  Input,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { isValidBIC, isValidIBAN } from 'ibantools';
import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BankAccountSelectProps {
  value?: BankAccountBase;
  onChange?: (value?: BankAccountBase) => void;
  options: PaymentSettingBankAccount[];
  disabled?: boolean;
}

const DEFAULT_LAYOUT: FormItemProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { margin: 0 },
};

export const BankAccountSelect: React.FunctionComponent<
  BankAccountSelectProps
> = ({ value, options, onChange }) => {
  const { isLoading, data: banks } = useGetAllBanks();
  const [manual, setManual] = useState(!options.length);

  const [form] = useForm();
  const { t } = useTranslation();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const onSubmit = (values) => {
    onChange?.({ iban: values.iban, bic: values.bic });
  };

  const getBicFromIban = (iban?: string) => {
    if (!iban || iban?.length < 8) {
      return;
    }
    const bankIdentifier = iban.substring(4, 8);
    const bic = banks?.find(
      (x: Bank) => x.bankIdentifier === bankIdentifier,
    )?.bic;
    return bic;
  };

  const handleIbanChange = (field: Key[], value?: string) => {
    const bic = getBicFromIban(value);
    if (!bic) {
      return;
    }
    form.setFieldValue(field, bic);
    form.validateFields();
  };

  const checkErrors = async () => {
    return await form
      .validateFields()
      .then(() => {
        form.submit();
        return false;
      })
      .catch(() => {
        showNotification(
          'error',
          t('api.payment.bankAccountNotFilled', { ns: 'errors' }),
        );
        return true;
      });
  };

  return (
    <>
      <CustomButton
        size="small"
        type="primary"
        color="secondary"
        className="font-scale"
        ghost
        onClick={() => setModalIsVisible(true)}
        icon={<SelectOutlined />}
      >
        {value?.iban || t('payment.ibanSelect.actions.select')}
      </CustomButton>
      <GenericModal
        isVisible={modalIsVisible}
        onOkHandler={checkErrors}
        hideModal={() => setModalIsVisible(false)}
        isLoading={isLoading}
      >
        <Typography.Paragraph>
          {t('payment.ibanSelect.labels.info')}
          <br />
          {t('payment.ibanSelect.labels.tip')}
        </Typography.Paragraph>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          preserve={false}
        >
          <Form.Item shouldUpdate={(pv, cv) => pv.manual !== cv.manual}>
            {() => {
              const isChecked = !options.length || manual;
              if (isChecked) {
                return (
                  <Form.Item
                    {...DEFAULT_LAYOUT}
                    label={t('payment.ibanSelect.labels.iban')}
                    name="iban"
                    initialValue={value?.iban}
                    rules={[
                      { required: true, message: 'Verplicht' },
                      {
                        validator: async (rule, value) => {
                          if (!value) {
                            return;
                          }
                          if (!isValidIBAN(value))
                            throw new Error('Ongeldige IBAN');
                        },
                      },
                    ]}
                  >
                    <ControlledInput
                      className={'dashed'}
                      placeholder={'Vul een IBAN in'}
                      normalize={(value) => (value || '').toUpperCase()}
                      onBlur={(e) => handleIbanChange(['bic'], e.target.value)}
                    />
                  </Form.Item>
                );
              }

              return (
                <Form.Item
                  {...DEFAULT_LAYOUT}
                  label={t('payment.ibanSelect.labels.iban')}
                  name="iban"
                  initialValue={value?.iban}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={options.map((x) => ({
                      label: `${x.iban} | ${x.name}`,
                      value: x.iban,
                    }))}
                    onSelect={(e) => handleIbanChange(['bic'], e)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate={(pv, cv) => pv.iban !== cv.iban}>
            {({ getFieldValue }) => {
              const bic = getBicFromIban(getFieldValue(['iban']));

              return (
                <Form.Item
                  {...DEFAULT_LAYOUT}
                  label={t('payment.ibanSelect.labels.bic')}
                  name="bic"
                  initialValue={value?.bic}
                  rules={[
                    { required: true, message: 'Verplicht' },
                    {
                      validator: async (rule, value) => {
                        if (!value) {
                          return;
                        }
                        if (!isValidBIC(value))
                          throw new Error('Ongeldige BIC');
                      },
                    },
                  ]}
                >
                  {bic ? (
                    <Input disabled />
                  ) : (
                    <ControlledInput
                      className={'dashed'}
                      value={bic}
                      placeholder={'BIC'}
                      normalize={(value) => (value || '').toUpperCase()}
                    />
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            {...DEFAULT_LAYOUT}
            label={t('payment.ibanSelect.labels.manual')}
            name="manual"
          >
            <Tooltip title={t('payment.ibanSelect.labels.manual')}>
              <Switch disabled={!options.length} onChange={setManual} />
            </Tooltip>
          </Form.Item>
        </Form>
      </GenericModal>
    </>
  );
};
