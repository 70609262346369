import { LabelValue, LabelValueModifyRequest } from '@/lib/types/labels';
import { Form, Input, Row } from 'antd';

import { CustomButton } from '@/lib/components';
import { useTranslation } from 'react-i18next';

interface IAddEditLabelValue {
  isLoading: boolean;
  value?: LabelValue;
  onFinish: (label: LabelValueModifyRequest) => void;
  onModalClose: () => void;
}

export const AddEditLabelValue = ({
  onFinish,
  isLoading,
  onModalClose,
  value,
}: IAddEditLabelValue) => {
  const { t } = useTranslation();
  return (
    <Form
      layout="vertical"
      onFinish={(values) => onFinish(values)}
      initialValues={value}
    >
      <Form.Item
        label={t('settings.tabs.labelmanagementDetails.form.name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('settings.tabs.labelmanagementDetails.form.description')}
        name="description"
      >
        <Input />
      </Form.Item>
      <Row justify="space-between">
        <CustomButton color="secondary" ghost onClick={onModalClose}>
          {t('general.actions.cancel')}
        </CustomButton>
        <CustomButton
          htmlType="submit"
          type="primary"
          color="secondary"
          loading={isLoading}
        >
          {t('settings.tabs.labelmanagementDetails.actions.save')}
        </CustomButton>
      </Row>
    </Form>
  );
};
