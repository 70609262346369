import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  GenericModal,
} from '@/lib/components';
import {
  useCreateIdentifierTypes,
  useDeleteIdentifierTypes,
  useGetIdentifierTypes,
  useUpdateIdentifierTypes,
} from '@/lib/queries';
import {
  IdentifierCategory,
  IdentifierType,
  IdentifierTypeModifyRequest,
} from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Popconfirm, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditIdentifierTypeForm } from '../_components/AddEditIdentifierTypeForm';

export const IdentifierSettings = () => {
  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.identifiers',
  });
  const { data, isLoading } = useGetIdentifierTypes();
  const { mutateAsync: createItem } = useCreateIdentifierTypes();
  const { mutateAsync: updateItem } = useUpdateIdentifierTypes();
  const { mutateAsync: deleteItem } = useDeleteIdentifierTypes();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IdentifierType>();

  const columns: CustomColumnType<IdentifierType>[] = [
    {
      title: t('fields.type'),
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: t('fields.categorie'),
      key: 'category',
      dataIndex: 'category',
      render: (value: string) => {
        return IdentifierCategory[value];
      },
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'id',
      width: '100px',
      render: (id: number, record: IdentifierType) => {
        return (
          <>
            <CustomButton
              type="link"
              onClick={() => openEditModal(record)}
              icon={<FontAwesomeIcon icon="pencil-alt" />}
            />
            <Popconfirm
              title={t('actions.delete.single', {
                type: record.type,
              })}
              onConfirm={() => onDelete(id)}
              okButtonProps={{
                shape: 'round',
              }}
              cancelButtonProps={{
                shape: 'round',
              }}
            >
              <CustomButton
                type="link"
                toolTipKey="identifierType.actions.delete"
                danger
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const openEditModal = (record: IdentifierType) => {
    setSelectedItem(record);
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setSelectedItem(undefined);
    setIsModalVisible(false);
  };

  const onModalFinish = async (formValues: Record<string, string>) => {
    const request: IdentifierTypeModifyRequest = {
      type: formValues.type,
      category: parseInt(formValues.category),
    };
    if (selectedItem) {
      await updateItem({ id: selectedItem.id, request });
      showNotification('success', t('messages.updateSuccess'));
    } else {
      await createItem(request);
      showNotification('success', t('messages.createSuccess'));
    }

    onModalClose();
  };

  const onDelete = async (id: number) => {
    await deleteItem(id);
    showNotification('success', t('messages.deleteSuccess'));
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            color="secondary"
            onClick={() => setIsModalVisible(true)}
          >
            {t('actions.add')}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <CustomTable
            rowKey="id"
            columns={columns}
            dataSource={data}
          ></CustomTable>
        </Col>
      </Row>
      <GenericModal
        isVisible={isModalVisible}
        hideModal={() => setIsModalVisible(false)}
        onCancelHandler={onModalClose}
        footer={false}
        destroyOnClose
        title={selectedItem ? t('actions.edit') : t('actions.add')}
      >
        <AddEditIdentifierTypeForm
          isLoading={isLoading}
          onModalClose={onModalClose}
          onFinish={onModalFinish}
          value={selectedItem}
        />
      </GenericModal>
    </>
  );
};
