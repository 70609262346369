import { CustomButton } from '@/lib/components';
import { IdentifierCategory, IdentifierType } from '@/lib/types';
import { convertEnumToArray } from '@/lib/utils/helpers';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

interface IAddEditInvoiceRejectReasonForm {
  isLoading: boolean;
  value?: IdentifierType;
  onFinish: (entity: Record<string, string>) => void;
  onModalClose: () => void;
}

const identifierCategories = convertEnumToArray(IdentifierCategory);

export const AddEditIdentifierTypeForm = ({
  onFinish,
  isLoading,
  value,
  onModalClose,
}: IAddEditInvoiceRejectReasonForm) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.identifiers',
  });
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values)}
      initialValues={value}
    >
      <Form.Item
        label={t('fields.type')}
        name="type"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('fields.categorie')}
        name="category"
        rules={[{ required: true }]}
      >
        <Select>
          {identifierCategories.map(({ key, value }) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row justify="space-between">
        <Col>
          <CustomButton
            ghost
            color="secondary"
            loading={isLoading}
            onClick={onModalClose}
          >
            {t('actions.cancel')}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton htmlType="submit" color="secondary" loading={isLoading}>
            {t('actions.save')}
          </CustomButton>
        </Col>
      </Row>
    </Form>
  );
};
