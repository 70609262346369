import { FC, ReactNode } from 'react';
import { CustomButton, CustomButtonProps } from './customButton';

export type ToggleButtonProps = CustomButtonProps & {
  active: boolean;
  icon: ReactNode;
  inactiveIcon?: ReactNode;
};

export const ToggleButton: FC<ToggleButtonProps> = ({
  active,
  icon,
  inactiveIcon,
  children,
  ...rest
}) => {
  return (
    <CustomButton
      {...rest}
      icon={active ? icon : inactiveIcon ?? icon}
      color={active ? 'primary' : 'secondary'}
      ghost={!active}
    >
      {children}
    </CustomButton>
  );
};
