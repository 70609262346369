import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Navigatable } from '@/lib/types';
import { ActionScopeItem, FileType, ScopeItemAction } from '@/lib/types/scope';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FolderViewOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../core/buttons';
import { UblDownloadButton } from '../invoice-buttons';
import { ScopeTrackingButton } from './scopeTrackingButton';

interface ScopeViewItemLineProps extends Navigatable {
  item: ActionScopeItem;
}

const getDescription = (item: ActionScopeItem): string => {
  if (item.action === ScopeItemAction.Deleted) {
    return `${item.fileType}.deleted`;
  }

  return `${item.fileType}.default`;
};

export const ScopeViewItemLine: React.FunctionComponent<
  ScopeViewItemLineProps
> = ({ item, onNavigate }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'scope' });
  const [hasAccess] = useAtom(hasAccessAtom);
  const navigate = useNavigate();

  const handleDossierView = () => {
    navigate(`/dossier/${item.id}`);
    onNavigate?.();
  };

  const buttonsRender = () => {
    if (item.deleted) {
      return null;
    }

    const buttons: React.ReactNode[] = [];
    if (item.fileType === FileType[FileType.Invoice]) {
      buttons.push(
        <CustomButton
          key="dossier-button"
          size="small"
          type="link"
          shape="circle"
          icon={<FolderViewOutlined />}
          toolTipKey="document.action.goDossier"
          onClick={handleDossierView}
        />,
      );
    }
    if (item.fileType === FileType[FileType.ProcessFile]) {
      if (hasAccess.isAdmin) {
        buttons.push(
          <UblDownloadButton
            key="ubl-button"
            processFileId={item.id as string}
          />,
        );
      }
      if (item.status === 'Duplicate' && item.statusDescription) {
        const match =
          item.statusDescription.match(RegExp('ScopeId:(.*)')) || [];
        const scopeId = match.length >= 2 ? match[1] : undefined;
        if (scopeId) {
          buttons.push(<ScopeTrackingButton size="small" scopeId={scopeId} />);
        }
      }
    }
    return <>{buttons}</>;
  };

  return (
    <Space size="small">
      <span>{dateTimeFormatter.toDateTimeFromUtc(item.created)}</span>
      <span>{t(`fileTypeProcessDescription.${getDescription(item)}`)}</span>
      {buttonsRender()}
    </Space>
  );
};
