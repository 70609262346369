import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadForm } from './uploadForm';

export const UploadButton: FC = () => {
  const { t } = useTranslation();
  const { relation, isFrozen } = useRelationContext();
  const [isVisible, visible] = useState(false);
  const hasAccess = useAtomValue(hasAccessAtom);

  const onCancel = () => {
    visible(false);
  };

  return (
    <>
      <CustomButton
        disabled={!relation || hasAccess.isAccountManager || isFrozen}
        color="secondary"
        shape="round"
        onClick={() => visible(true)}
        icon={<FontAwesomeIcon icon="plus" />}
        toolTipKey="menu.label.upload"
      >
        {t('label.upload')}
      </CustomButton>
      <UploadForm visible={isVisible} onCancel={onCancel} />
    </>
  );
};
