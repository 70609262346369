import LogoImg from '@/assets/logo.png';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

interface LogoProps {
  text: string;
  iconOnly?: boolean;
}

export const Logo: FC<LogoProps> = ({ text, iconOnly }) => {
  const navigate = useNavigate();
  const goToHome = () => navigate('/');
  return (
    <Row
      gutter={8}
      onClick={goToHome}
      align="middle"
      style={{ lineHeight: 'normal' }}
    >
      <Col>
        <div className={styles.logo}>
          <img src={LogoImg}></img>
        </div>
      </Col>
      {!iconOnly && (
        <Col>
          <Typography.Text style={{ fontSize: '1.1rem' }}>
            {text}
          </Typography.Text>
        </Col>
      )}
    </Row>
  );
};
