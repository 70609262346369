import { Accent, CustomButton, GenericModal } from '@/lib/components';
import { Heading } from '@/lib/components/core/typography/heading';
import { RelationInvoiceStatistics } from '@/lib/types';
import { Relation } from '@/lib/types/user';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { showNotification } from '@/lib/utils/showNotification';
import { Col, Descriptions, Row, Space, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getRelationInvoiceStatistics,
  requestInvoiceMigration,
} from '../../../lib/adapters/invoice-management-adapter';
import { RelationDeleteModal } from '../relations/_components/relationDeleteModal';
import { RelationSearch } from './_components/relationSearch';

export const MergeRelations = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fromRelation, setFromRelation] = useState<Relation>();
  const [toRelation, setToRelation] = useState<Relation>();
  const [statistics, setStatistics] = useState<RelationInvoiceStatistics>();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isMergeVisible, setIsMergeVisible] = useState(false);
  const [includeDocuments, setIncludeDocuments] = useState(true);
  const fetchStatistics = async (relation: Relation) => {
    try {
      const response = await getRelationInvoiceStatistics({
        relationId: relation.id,
      });
      setStatistics(response);
    } catch (e) {
      showNotification('error', 'Kan statistieken niet ophalen.');
      setStatistics(undefined);
    }
  };

  const clearState = () => {
    setFromRelation(undefined);
    setToRelation(undefined);
    setStatistics(undefined);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!fromRelation) {
      return;
    }

    fetchStatistics(fromRelation);
  }, [fromRelation]);

  const executeMerge = async () => {
    if (!fromRelation || !toRelation) {
      return;
    }

    if (fromRelation.id === toRelation.id) {
      showNotification(
        'info',
        'Van en naar relatie kunnen niet hetzelfde zijn',
      );
      return;
    }
    try {
      setIsLoading(true);
      await requestInvoiceMigration({
        oldOwnerId: fromRelation.id,
        newOwnerId: toRelation.id,
        includeDocuments,
      });
      showNotification('success', 'Samenvoegen van bedrijven gestart');
      setIsDeleteModalVisible(true);
    } catch {
      showNotification('error', 'Samenvoegen van bedrijven mislukt');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <RelationSearch
            disabled={false}
            relation={fromRelation}
            title={t('merge.from')}
            onSelect={(relation) => setFromRelation(relation)}
          />
        </Col>
        <Col span={8}>
          <RelationSearch
            disabled={!fromRelation}
            relation={toRelation}
            title={t('merge.to')}
            onSelect={(relation) => setToRelation(relation)}
          />
        </Col>
        <Col span={8}>
          <Heading type="h2" text={t('merge.summary')} />
          <Descriptions
            style={{ textAlign: 'left' }}
            layout="horizontal"
            column={1}
            size="small"
          >
            <Descriptions.Item label={t('merge.statistics.totalInvoices')}>
              <Accent type="strong">{statistics?.totalInvoices}</Accent>
            </Descriptions.Item>
            <Descriptions.Item label={t('merge.statistics.firstInvoice')}>
              <Accent type="strong">
                {statistics?.firstInvoice &&
                  dateTimeFormatter.toDateOnly(statistics.firstInvoice)}
              </Accent>
            </Descriptions.Item>
            <Descriptions.Item label={t('merge.statistics.lastInvoice')}>
              <Accent type="strong">
                {statistics?.lastInvoice &&
                  dateTimeFormatter.toDateOnly(statistics.lastInvoice)}
              </Accent>
            </Descriptions.Item>
          </Descriptions>

          <CustomButton
            shape="round"
            type="primary"
            color="secondary"
            loading={isLoading}
            onClick={() => setIsMergeVisible(true)}
            toolTipKey="mergerelations.action.merge"
            disabled={!fromRelation || !toRelation || !statistics}
          >
            {t('merge.actions.merge')}
          </CustomButton>
        </Col>
      </Row>
      <GenericModal
        onOkHandler={executeMerge}
        okText={t('merge.actions.merge')}
        isVisible={isMergeVisible}
        hideModal={() => setIsMergeVisible(false)}
        destroyOnClose
      >
        <Typography.Paragraph>
          {t('merge.prompts.confirm')}
        </Typography.Paragraph>
        <Space>
          <Typography.Text>
            {t('merge.prompts.includeDocuments')}
          </Typography.Text>
          <Switch checked={includeDocuments} onChange={setIncludeDocuments} />
        </Space>
      </GenericModal>
      {fromRelation && (
        <RelationDeleteModal
          confirmText={t('merge.prompts.delete')}
          relation={fromRelation}
          isModalVisible={isDeleteModalVisible}
          setIsModalVisible={setIsDeleteModalVisible}
          onCancelHandler={clearState}
          onSubmitHandler={clearState}
        />
      )}
    </>
  );
};
